
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Tag, Button, ButtonArrow, ErrorSummary, LoadingBox } from "govuk-react";
import { useMsal } from "@azure/msal-react";
import yn from "yn";

import { WorkspaceContext } from "../../contexts/WorkspaceContext";
import { IsOnlineContext } from "../../contexts/IsOnlineContext";
import { WorkspaceRoleName, RoleName } from "../models/roleNames";
import VM from "../ui/VM";
import { useAuthApiCall } from "../hooks/useAuthAPICall";
import { ApiEndpoint } from "../models/apiEndPoints";
import { CallApiWithToken, HttpMethod } from "../Core/fetch";
import { ResourceVMType, VMPowerStates } from "../models/resource";
import { AppRolesContext } from "../../contexts/AppRolesContext";
import { Title } from "../ui/Title";
import { Subtitle } from "../ui/Subtitle";
import { SmallTitle } from "../ui/SmallTitle";
import { TextLink } from "../ui/TextLink";
import { Selectbox } from "../ui/Selectbox";
import Pagination from "../ui/Pagination";
import { ToggleButton } from "../ui/ToggleButton";

import './WorkspaceUserResourcesData.css';
import { graphConfig, loginRequest, trecoreServicesConfig } from "../Core/authConfig";
import { CheckOps } from "../../services/CheckOps";
import { useInterval } from "../hooks/useInterval";
import { Lede } from "../ui/Lede";
import { Modal } from "../ui/Modal";
import { error } from "../../types";
import { UpdateWorkspaceServiceRequest, WorkspaceType } from "../models/workspace";
import { NotificationBox } from "../ui/NotificationBox";
import { TableHead } from "../ui/TableHead";
import { Td } from "../ui/GDS-components/Table";
import { Operation, completedStates, inProgressStates, successStates } from "../models/operations";
import { WorkspaceOwnerType } from "../models/user";

/**
 * Renders information about the workspace obtained from TRE Core API
 * @param props 
 */

type WorkspaceUserResourcesDataProps = {
  trecoreData: ResourceVMType[],
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>,
  serviceUpdating: boolean,
  isParentServicePaused: boolean
}

 export const WorkspaceUserResourcesData = ({trecoreData, setRefresh, serviceUpdating, isParentServicePaused}: WorkspaceUserResourcesDataProps) => {
  const apiCall = useAuthApiCall();
  const { instance, accounts } = useMsal();
  const appRolesCtx = useContext(AppRolesContext);
  const workspaceCtx = useContext(WorkspaceContext);
  const onlineCtx = useContext(IsOnlineContext);
  const [filterData, setFilterData] = useState<[] | ResourceVMType[]>([]);
  const [allFilterData, setAllFilterData] = useState<[] | ResourceVMType[]>([]);
  const [isVMBeingUpdated, setIsVMBeingUpdated] = useState(true);
  const [updateVMStatus, setUpdateVMStatus] = useState<null | string>(null);
  const [action, setAction] = useState<null | string>(null);
  const [isVMUpdating, setVMUpdating] = useState(false);
  const [isActionInvoked, setIsActionInvoked] = useState<null | boolean>(null);
  const [isLoadingAction, setLoadingAction] = useState(false);
  const [isWorkspaceEnabled, setIsWorkspaceEnabled] = useState(false);
  const [workspaceOwner, setWorkspaceOwner] = useState<[] | WorkspaceOwnerType[]>([]);
  const [sort, setSort] = useState<string | null>();

  const isResearcher = workspaceCtx.roles.length === 1 && workspaceCtx.roles[0] === WorkspaceRoleName.WorkspaceResearcher;

  useEffect(() => {
    setIsWorkspaceEnabled(workspaceCtx.workspace.isEnabled);
  }, []);

  useEffect(() => {
    workspaceCtx.workspace.properties && (
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
      }).then(async res => {
        await CallApiWithToken(res.accessToken, graphConfig.graphMeEndpoint, HttpMethod.Get, '')
          .then(async (response) => {
            await CallApiWithToken(
              res.accessToken,
              `${graphConfig.graphGroups}?$filter=displayName eq '${process.env.REACT_APP_ENV}-ws-${workspaceCtx.workspace.id.slice(-4)} ${graphConfig.graphGroupWorkspaceOwners}'`,
              HttpMethod.Get,
              ''
            ).then(async (response) => {
              return response.value.length > 0 ? (
                await CallApiWithToken(
                  res.accessToken,
                  `${graphConfig.graphGroups}/${response.value[0].id}/members`,
                  HttpMethod.Get,
                  ''
                ).then(response => {
                  setWorkspaceOwner(response.value)
                }).catch(err => {
                  console.log("err: ", err);
                })
              ) : (
                null
              )
            }).catch(err => {
              console.log("err: ", err);
            })
          })
      })
    )
  }, [workspaceCtx]);

  useEffect(() => {
    setFilterData(trecoreData);
    setAllFilterData(trecoreData);
  }, [trecoreData]);
  
  let navigate = useNavigate();
  let params = useParams();

  const delay = 5;     
    
  const executeAction = async (actionName: string, path: string) => {
    setLoadingAction(true)        
    let actualpath = `${path}/${ApiEndpoint.InvokeAction}?action=${actionName}`;        
    const action = await apiCall(actualpath, HttpMethod.Post, workspaceCtx.workspaceApplicationIdURI);
    setIsActionInvoked(action);
    setLoadingAction(false)        
  }

  const resetAction = async () => {
		setIsActionInvoked(null);
	}

  useEffect(() => {
    resetAction()
    yn(process.env.REACT_APP_DEUB) && console.log('useEffect: resetAction...')
  }, [trecoreData])

  const routeChange = () => {
    let path = `/Workspaces/${params.id}/workspace-services/${params.rid}/createvm`;
    navigate(path);
  }

  const onAction = (e: string, resource: ResourceVMType) => {
    if (e === null) return false;
    if (e === "Stop") {
      return (
        executeAction(`stop`, resource.resourcePath),
        setIsVMBeingUpdated(true),
        setUpdateVMStatus(resource.id),
        setAction("stop")
      )
    } else if (e === "Start") {
      return (
        executeAction(`start`, resource.resourcePath),
        setIsVMBeingUpdated(true),
        setUpdateVMStatus(resource.id),
        setAction("start")
      )
    }
  }

  const onSort = (e: string) => {
    setSort(e);
    if (e === null) {
      return setFilterData(allFilterData);
    } else if (e === "Active") {
      setFilterData(allFilterData.filter((item: ResourceVMType) => item.azureStatus.powerState === VMPowerStates.Running || item.azureStatus.powerState === VMPowerStates.Starting));
    } else {
      setFilterData(allFilterData.filter((item: ResourceVMType) => item.deploymentStatus === "deployment_failed" || item.azureStatus.powerState === VMPowerStates.Deallocated || item.azureStatus.powerState === VMPowerStates.Stopped || item.azureStatus.powerState === VMPowerStates.Stopping || item.azureStatus.powerState === VMPowerStates.Deallocating || item.azureStatus.powerState === undefined));
    }
  }

  const getStatus = () => {
    return isVMUpdating ? (
      action === "stop" ? "is being stopped" : "is being started"
    ) : (
      action === "start" ? "has started" : "has stopped"
    );
  }

  console.log("workspaceOwner: ", workspaceOwner);

  return (
    <>
      {isVMBeingUpdated && updateVMStatus && !isLoadingAction && (
        <div className="workspace-user-resources-data__updating-VM">
          <p className="workspace-user-resources-data__update-VM-text">Your VM: <span className="workspace-user-resources-data__update-VM-text--bold">{getStatus()}</span></p>
        </div>
      )}
      <section className="workspace-user-resources-data">
        <Title>List of VMs</Title>
        <Subtitle>Workspace name: {workspaceCtx.workspace.properties.display_name}</Subtitle>
        <article className="workspace-user-resources-data__header-bar">
          <div className="workspace-user-resources-data__workspace-owner-wrapper">
            <SmallTitle>Workspace owner(s):</SmallTitle>
            {workspaceOwner && workspaceOwner.length > 0 && (
              <ul className="workspace-user-resources-data__workspace-owner-list">
                {workspaceOwner.map((owner: WorkspaceOwnerType) => (
                  <li className="workspace-user-resources-data__workspace-owner">{owner.displayName}</li>
                ))}
              </ul>
            )}
          </div>
          {(appRolesCtx.roles?.includes(RoleName.TREAdmin)) && (
            <div>
              <SmallTitle>Admin</SmallTitle>
              <TextLink to={`/manage-workspaces/${workspaceCtx.workspace.id}`} state={{workspace: workspaceCtx.workspace}}>Manage users</TextLink>
            </div>
          )}
        </article>
        <div className="workspace-user-resources-data__airlock-button">
          <Button as={Link} className="govuk-button" to={`/Workspaces/${workspaceCtx.workspace.id}/airlocks`}>Airlock dashboard</Button>
        </div>
        <article className="workspace-user-resources-data__large-column">
          {serviceUpdating ? (
            <Lede className="workspace-user-resources-data__notification">Service is updating</Lede>
          ) : (
            <>
              <NumberDisplay resources={filterData} />
              {!isWorkspaceEnabled && <Lede className="workspace-user-resources-data__notification">This workspace is currently paused</Lede>}
              {isParentServicePaused ? (
                <Lede className="workspace-user-resources-data__notification">The guacamole workspace service is currently paused</Lede>
              ) : (
                <header className="workspace-user-resources-data__header">
                  <Selectbox
                    label="Filter by"
                    options={[
                      {
                        name: "Active",
                        value: "Active"
                      },
                      {
                        name: "Inactive",
                        value: "Inactive"
                      }
                    ]}
                    onChange={(e: string) => onSort(e)}
                  />
                  {!isParentServicePaused && isWorkspaceEnabled && onlineCtx.isOnline && (
                    <>
                      {isResearcher ? (
                        <>
                          {trecoreData && trecoreData.length < 1 && (
                            <Button
                              buttonColour="#3f78c0"
                              className="workspace-services__create-new"
                              icon={<ButtonArrow />}
                              onClick={() => routeChange()}
                              start
                            >
                              Create new
                            </Button>
                          )}
                        </>
                      ) : (
                        <Button
                          buttonColour="#3f78c0"
                          className="workspace-services__create-new"
                          icon={<ButtonArrow />}
                          onClick={() => routeChange()}
                          start
                        >
                          Create new
                        </Button>
                      )}
                    </>
                  )}
                </header>
              )}
              {filterData && (
                (!isParentServicePaused || isWorkspaceEnabled) ? (
                  <TRECoreWorkspaceDetailData
                    filterData={filterData}
                    onAction={onAction}
                    isActionInvoked={isActionInvoked}
                    setRefresh={setRefresh}
                    updateVMStatus={updateVMStatus}
                    sort={sort}
                    onSort={onSort}
                    setVMUpdating={setVMUpdating}
                  />
                ) : (
                  (!isParentServicePaused || !isWorkspaceEnabled) && (
                    (appRolesCtx.roles?.includes(WorkspaceRoleName.WorkspaceOwner) || appRolesCtx.roles?.includes(RoleName.TREAdmin)) && (
                      <TRECoreWorkspaceDetailData
                        filterData={filterData}
                        onAction={onAction}
                        isActionInvoked={isActionInvoked}
                        setRefresh={setRefresh}
                        updateVMStatus={updateVMStatus}
                        sort={sort}
                        onSort={onSort}
                        setVMUpdating={setVMUpdating}
                      />
                    )
                  )
                )
              )}
              {isLoadingAction && <p><i>Preparing - please wait</i></p>}
            </>
          )}
        </article>
      </section>
    </>
  );
};

type TRECoreWorkspaceDetailDataProps = {
  filterData: [] | ResourceVMType[],
  onAction: (e: string, resource: ResourceVMType) => void,
  isActionInvoked: boolean | null,
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>,
  updateVMStatus: null | string,
  sort: string | null | undefined,
  onSort: (e: string) => void,
  setVMUpdating: React.Dispatch<React.SetStateAction<boolean>>
}

const TRECoreWorkspaceDetailData = ({ filterData, onAction, isActionInvoked, setRefresh, updateVMStatus, sort, onSort, setVMUpdating }: TRECoreWorkspaceDetailDataProps) => {
  const [data, setData] = useState<[] | ResourceVMType[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  useEffect(() => {
    setData(filterData.slice(indexOfFirstRecord, indexOfLastRecord));
  }, [filterData, currentPage, updateVMStatus]);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const nPages = filterData && Math.ceil(filterData.length / recordsPerPage);

  const TableHeaders = [
    {
      header: "VM name"
    },
    {
      header: "User",
    },
    {
      header: "Current status"
    },
    {
      header: "Actions",
      colSpan: 3
    }
  ]

  return (
    <>
      <table className="workspace-user-resources-data__table">
        <TableHead headers={TableHeaders} />
        <tbody>
          {data && data.length > 0 && data.map((resource: ResourceVMType) => (
            <VmRow
              key={resource.id}
              resource={resource}
              setRefresh={setRefresh}
              onAction={onAction}
              sort={sort}
              onSort={onSort}
              isActionInvoked={isActionInvoked}
              setVMUpdating={setVMUpdating}
            />
          ))}
        </tbody>
      </table>
      {nPages > 1 ? (
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : null}
    </>
  );
}

type VmRowProps = {
  resource: ResourceVMType,
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>,
  onAction: (e: string, resource: ResourceVMType) => void,
  sort: string | null | undefined,
  onSort: (e: string) => void,
  isActionInvoked: boolean | null,
  setVMUpdating: React.Dispatch<React.SetStateAction<boolean>>
}

const VmRow = ({ resource, setRefresh, onAction, sort, onSort, isActionInvoked, setVMUpdating }: VmRowProps) => {
  const { instance, accounts } = useMsal();
  const workspaceCtx = useContext(WorkspaceContext);
  const [operations, setOperations] = useState<Operation>();
  const [updating, setUpdating] = useState(false);
  const [vmSizeUpdated, setVmSizeUpdated] = useState(false);

  const callOperations = () => {
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
    }).then(async (response: any) => {
      await CallApiWithToken(
        response.accessToken,
        `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${workspaceCtx.workspace.id}/${ApiEndpoint.WorkspaceServices}/${resource.parentWorkspaceServiceId}/${ApiEndpoint.UserResources}/${resource.id}/${ApiEndpoint.Operations}`,
        HttpMethod.Get,
        ''
      ).then(response => {
        setOperations(response.operations);
        CheckOps(resource, response.operations, setUpdating, setRefresh, updating);
      })
    })
  }

  useEffect(() => {
    workspaceCtx.workspace.properties && callOperations();
  }, [workspaceCtx, vmSizeUpdated, isActionInvoked]);

  useEffect(() => {
    sort && onSort(sort);
    setVMUpdating(updating);
  }, [updating]);

  return (
    <VmRowItem
      resource={resource}
      updating={updating}
      callOperations={callOperations}
      onAction={onAction}
      setVmSizeUpdated={setVmSizeUpdated}
      setUpdating={setUpdating}
    />
  )
}

type VmModalProps = {
  resource: ResourceVMType,
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setVmSizeUpdated: React.Dispatch<React.SetStateAction<boolean>>,
  setUpdating: React.Dispatch<React.SetStateAction<boolean>>
}

const VmModal = ({ resource, setModalOpen, setVmSizeUpdated, setUpdating }: VmModalProps) => {
  const [initialVmSize, setInitialVmSize] = useState(resource.properties.vm_size ? resource.properties.vm_size : "4 CPU | 32GB RAM");
  const [vmSize, setVmSize] = useState(resource.properties.vm_size ? resource.properties.vm_size : "4 CPU | 32GB RAM");
  const [errors, setErrors] = useState<null | error[]>(null);
  const [apiError, setApiError] = useState(null);
  const [apiLoading, setApiLoading] = useState(false);
  const [requestSuccessful, setRequestSuccessful] = useState<null | string>(null);
  const resourceVmSizeInputRef = useRef<HTMLSelectElement>(null);
  const workspaceCtx = useContext(WorkspaceContext);
  const { instance, accounts } = useMsal();
  const location = useParams();
  const workspaceServiceId = location.rid;

  const makeApiCall = async (payload: any, workspace: WorkspaceType) => {
    const tokenRequest = {
      scopes: [`${workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`],
      account: accounts[0]
    }

    setApiError(null);
    setApiLoading(true);

    instance.acquireTokenSilent(tokenRequest).then(async (response) => {
      await CallApiWithToken(
        response.accessToken,
        `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${workspace.id}/${ApiEndpoint.WorkspaceServices}/${workspaceServiceId}/${ApiEndpoint.UserResources}/${resource.id}`,
        HttpMethod.Patch,
        payload,
        "*"
      ).then(response => {
        setRequestSuccessful(response.operation.message);
        setVmSizeUpdated(true);
        setUpdating(true);
        setApiLoading(false);
      }).catch((err: any) => {
        setApiError(err.message);
        setApiLoading(false);
      }).catch((err: any) => {
        setApiError(err.message);
        setApiLoading(false);
      })
      yn(process.env.REACT_APP_DEBUG) && console.log("User resource update request submitted");
    })
  }

  const createPayload = () => {
    const resourceVmSize = resourceVmSizeInputRef.current?.value;
    let properties: UpdateWorkspaceServiceRequest["properties"] = {}

    properties = {
      vm_size: resourceVmSize
    };

    const newUpdateWorkspaceServiceRequest : UpdateWorkspaceServiceRequest = {
      properties: properties
    };

    resourceVmSize === resource.properties.vm_size ? (
      setRequestSuccessful("VM is already this size")
    ) : (
      makeApiCall(newUpdateWorkspaceServiceRequest, workspaceCtx.workspace)
    )
  }

  const hasError = (formItem: string) => {
    const checkError = (obj: error) => obj.targetName === formItem;
    return errors && errors.some(checkError);
  }

  const onSubmit = () => {
    setErrors(null);
    const newErrors = [];
    const resourceVmSize = resourceVmSizeInputRef.current?.value;
    if (resourceVmSize === "Select") {
      newErrors.push({
        targetName: "vm-size",
        text: "Select a VM size. Default size is 4 CPU | 32GB RAM"
      })
    }

    return newErrors.length > 0 ? (
      setErrors(newErrors)
    ) : (
      createPayload()
    )
  }

  const getOptions = () => {
    let options = [
      {
        name: "2 CPU | 4GB RAM",
        value: "2 CPU | 4GB RAM"
      },
      {
        name: "4 CPU | 32GB RAM",
        value: "4 CPU | 32GB RAM"
      },
      {
        name: "4 CPU | 64GB RAM",
        value: "4 CPU | 64GB RAM"
      }
    ];

    return options;
  }

  return (
    <LoadingBox loading={apiLoading}>
      <Modal
        action="Update"
        copy={`Select a VM size`}
        header="Update VM"
        options={
          <>
            {(apiError || requestSuccessful) && (
              <NotificationBox
                id="notification"
                error={apiError}
                text={`${requestSuccessful}. You can close this modal.`}
              />
            )}
            {errors && (
              <ErrorSummary
                errors={errors}
                heading="There is a problem submitting your request"
                onHandleErrorClick={(e: any) => document.getElementById(e)?.scrollIntoView()}
              />
            )}
            <Selectbox
              className="workspace-user-resources-data__select"
              id="vm-size"
              label="VM size"
              forwardRef={resourceVmSizeInputRef}
              defaultValue={vmSize}
              error={hasError("vm-size")}
              errorText="Select a VM size. Default size is 4 CPU | 32GB RAM"
              hint="Select the size of the VM"
              options={getOptions()}
              onChange={(e: string) => setVmSize(e)}
            />
          </>
        }
        onAction={() => onSubmit()}
        onExit={() => setModalOpen(false)}
        title={`Update the size of ${resource.properties.display_name} VM`}
        warning={initialVmSize === "4 CPU | 32GB RAM" && vmSize === "4 CPU | 64GB RAM" && (
          <p className="workspace-user-resources-data__warning-text">Your current VM is charged at £0.60/hour for using 32GB. 64GB VMs are charged at £0.90/hour. (This charge will be drawn down from your VM usage allocation)</p>
        )}
        disableButton={requestSuccessful ? true : false}
      />
    </LoadingBox>
  )
}

type VmRowItemProps = {
  resource: ResourceVMType,
  updating: boolean,
  callOperations: () => void,
  onAction: (e: string, resource: ResourceVMType) => void,
  setVmSizeUpdated: React.Dispatch<React.SetStateAction<boolean>>,
  setUpdating: React.Dispatch<React.SetStateAction<boolean>>
}

const VmRowItem = ({resource, updating, callOperations, onAction, setVmSizeUpdated, setUpdating}: VmRowItemProps) => {
  const [showDetails, setShowDetails] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [createdBy, setCreatedBy] = useState<null | string>(null);
  const { instance, accounts } = useMsal();
  useInterval(() => callOperations(), updating ? 10000 : null);
  const workspaceCtx = useContext(WorkspaceContext);
  const onlineCtx = useContext(IsOnlineContext);

  useEffect(() => {
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    }).then(async (res) => {
      await CallApiWithToken(
        res.accessToken,
        `${graphConfig.graphUsersEndpoint}/${resource.ownerId}`,
        HttpMethod.Get,
        ''
      ).then(response => {
        setCreatedBy(response.displayName);
        setLoading(false);
      }).catch((err: any) => {
        console.log("err: ", err);
      })
    })
  }, []);

  return (
    <>
      {modalOpen && (
        <VmModal resource={resource} setModalOpen={setModalOpen} setVmSizeUpdated={setVmSizeUpdated} setUpdating={setUpdating} />
      )}
      <tr key={resource.id}>
        <Td className={`${showDetails ? ' workspace-user-resources-data__table-cell' : ""}`}>
          <ToggleButton onClick={() => setShowDetails(!showDetails)}>{resource.properties.display_name}</ToggleButton>
        </Td>       
        <Td className={`${showDetails ? ' workspace-user-resources-data__table-cell' : ""}`}>
          {loading ? (
            "loading..."
          ) : (
            createdBy
          )}
        </Td>
        <Td className={`${showDetails ? ' workspace-user-resources-data__table-cell' : ""}`}>
          {updating ? (
            "loading..."
          ) : (
            (() => {
              if (resource.azureStatus.powerState === VMPowerStates.Running) {
                return <Tag backgroundColor="#e8f1f8" className="workspace-user-resources-data__running-tag">Running</Tag>
              } else if(resource.azureStatus.powerState === VMPowerStates.Deallocated) {
                return <p style={{marginBottom: '0'}}>Deallocated</p>
              } else if(resource.deploymentStatus === "deployment_failed") {
                return <Tag backgroundColor="#eeefef" color="#383f43">Deployment failed</Tag>
              } else {
              return <p style={{color:'#85994b', marginBottom: '0'}}><b><i>{resource.azureStatus.powerState}...</i></b></p>
              }
            })()
          )}
        </Td>
        <Td className={`${showDetails ? ' workspace-user-resources-data__table-cell' : ""}`}>
          {onlineCtx.isOnline && (
            updating ? (
              "loading..."
            ) : (
              <Selectbox
                label={resource.azureStatus.powerState === VMPowerStates.Running ? "Stop virtual machine" : "Start virtual machine"}
                options={[
                  {
                    name: resource.azureStatus.powerState === VMPowerStates.Running ? "Stop" : "Start",
                    value: resource.azureStatus.powerState === VMPowerStates.Running ? "Stop" : "Start"
                  }
                ]}
                onChange={(e: string) => onAction(e, resource)}
                hiddenLabel
              />
            )
          )}
        </Td>
        <Td className={`${showDetails ? ' workspace-user-resources-data__table-cell' : ""}`}>
          {onlineCtx.isOnline && (
            updating ? (
              "loading..."
            ) : (
              workspaceCtx.workspace.isEnabled && resource.azureStatus.powerState === VMPowerStates.Running ? <VM trecoreData={resource.properties.connection_uri} /> : null
            )
          )}
        </Td>
        {workspaceCtx.roles?.includes(WorkspaceRoleName.WorkspaceOwner) && (
          <Td className={`${showDetails ? ' workspace-user-resources-data__table-cell' : ""}`}>
            {onlineCtx.isOnline && (
              updating ? (
                "loading..."
              ) : (
                <Button
                  className="workspace-user-resources-data__vm-button"
                  buttonColour="#3f78c0"
                  onClick={() => setModalOpen(true)}
                >
                  Update VM size
                </Button>
              )
            )}
          </Td>
        )}
      </tr>
      {showDetails && (
        <>
          <tr>
            <Td className="workspace-user-resources-data__table-cell" colspan={6}>
              <span>ID: </span>
              <span className="workspace-user-resources-data__grey">{resource.id}</span>
            </Td>
          </tr>
          <tr className="workspace-user-resources-data__table-row">
            <Td className="govuk-table__cell" colspan={6}>
              <span>Template name: </span>
              <span className="workspace-user-resources-data__grey">{resource.templateName}</span>
            </Td>
          </tr>
        </>
      )}
    </>
  )
}

type NumberDisplayProps = {
  resources: ResourceVMType[];
}

const NumberDisplay = ({ resources }: NumberDisplayProps) => {
  const getUsers = (resources: any) => {
    const allUsers: any[] = [];
    resources.map((item: any) => {
      allUsers.push(item.user);
    });
    const uniqueUsers = Array.from(new Set(allUsers.map(item => item.id))).map(id => {
      return allUsers.find(item => item.id === id)
    });

    return uniqueUsers.length;
  }

  const users = getUsers(resources);
  const activeVms = resources.filter((item: any) => item.azureStatus.powerState === VMPowerStates.Running || item.azureStatus.powerState === VMPowerStates.Starting);
  const inactiveVms = resources.filter((item: any) => item.deploymentStatus === "deployment_failed" || item.azureStatus.powerState === VMPowerStates.Deallocated || item.azureStatus.powerState === VMPowerStates.Stopped || item.azureStatus.powerState === VMPowerStates.Stopping || item.azureStatus.powerState === VMPowerStates.Deallocating || item.azureStatus.powerState === undefined);

  return (
    <article className="number-display">
      <p className="number-display__figure">{users ? users : '--'} <span className="number-display__figure-text">Users</span></p>
      <p className="number-display__figure">{activeVms ? activeVms.length : '--'} <span className="number-display__figure-text">Active VMs</span></p>
      <p className="number-display__figure">{inactiveVms ? inactiveVms.length : '--'} <span className="number-display__figure-text">Inactive VMs</span></p>
    </article>
  )
}
