
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tag, Button, ButtonArrow, ErrorSummary, LoadingBox } from "govuk-react";
import { useMsal } from "@azure/msal-react";
import yn from "yn";

import { WorkspaceContext } from "../../contexts/WorkspaceContext";
import { IsOnlineContext } from "../../contexts/IsOnlineContext";
import { WorkspaceRoleName, RoleName } from "../models/roleNames";
import VM from "../ui/VM";
import { useAuthApiCall } from "../hooks/useAuthAPICall";
import { ApiEndpoint } from "../models/apiEndPoints";
import { CallApiWithToken, HttpMethod } from "../Core/fetch";
import { VMPowerStates } from "../models/resource";
import { AppRolesContext } from "../../contexts/AppRolesContext";
import { Title } from "../ui/Title";
import { Subtitle } from "../ui/Subtitle";
import { SmallTitle } from "../ui/SmallTitle";
import { TextLink } from "../ui/TextLink";
import { Selectbox } from "../ui/Selectbox";
import Pagination from "../ui/Pagination";
import { ToggleButton } from "../ui/ToggleButton";

import './WorkspaceUserResourcesData.css';
import { graphConfig, loginRequest, trecoreServicesConfig } from "../Core/authConfig";
import { CheckOps } from "../../services/CheckOps";
import { useInterval } from "../hooks/useInterval";
import { Lede } from "../ui/Lede";
import { Modal } from "../ui/Modal";
import { error } from "../../types";
import { UpdateWorkspaceServiceRequest } from "../models/workspace";
import { NotificationBox } from "../ui/NotificationBox";
import { TableHead } from "../ui/TableHead";
import { Td } from "../ui/GDS-components/Table";

/**
 * Renders information about the workspace obtained from TRE Core API
 * @param props 
 */

 export const WorkspaceUserResourcesData = ({trecoreData, setRefresh, serviceUpdating, isParentServicePaused}: any) => {
  const apiCall = useAuthApiCall();
  const { instance, accounts } = useMsal();
  const appRolesCtx = useContext(AppRolesContext);
  const workspaceCtx = useContext(WorkspaceContext);
  const onlineCtx = useContext(IsOnlineContext);
  const [filterData, setFilterData] = useState([]);
  const [allFilterData, setAllFilterData] = useState([]);
  const [isVMBeingUpdated, setIsVMBeingUpdated] = useState(true);
  const [updateVMStatus, setUpdateVMStatus] = useState<any>(null);
  const [action, setAction] = useState<null | string>(null);
  const [isActionInvoked, setIsActionInvoked] = useState<any>(null);
  const [isLoadingAction, setLoadingAction] = useState(false);
  const [isVMCreate, setVMCreate] = useState(false);
  const [isWorkspaceEnabled, setIsWorkspaceEnabled] = useState(false);
  const [workspaceOwner, setWorkspaceOwner] = useState<null | string>(null);
  const [sort, setSort] = useState<string | null>();

  useEffect(() => {
    setIsWorkspaceEnabled(workspaceCtx.workspace.isEnabled);
  }, []);

  useEffect(() => {
    workspaceCtx.workspace.properties && (
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
      }).then(async (res) => {
        await CallApiWithToken(res.accessToken, graphConfig.graphMeEndpoint, HttpMethod.Get, '')
          .then(async (response) => {
            await CallApiWithToken(
              res.accessToken,
              `${graphConfig.graphUsersEndpoint}/${workspaceCtx.workspace.properties.workspace_owner_object_id}?$select=displayName`,
              HttpMethod.Get,
              ''
            ).then(response => {
              setWorkspaceOwner(response.displayName);
            })
          })
      })
    )
  }, [workspaceCtx]);

  useEffect(() => {
    setFilterData(trecoreData.userResources);
    setAllFilterData(trecoreData.userResources);
  }, [trecoreData])
  
  let navigate = useNavigate();
  let params = useParams();

  const delay = 5;     
    
  const executeAction = async (actionName: string, path: string) => {
    setLoadingAction(true)        
    let actualpath = `${path}/${ApiEndpoint.InvokeAction}?action=${actionName}`;        
    const action = await apiCall(actualpath, HttpMethod.Post, workspaceCtx.workspaceApplicationIdURI);
    setIsActionInvoked(action);
    setLoadingAction(false)        
  }

  const resetAction = async () => {
		setIsActionInvoked(null);
	}

  useEffect(() => {
    resetAction()
    yn(process.env.REACT_APP_DEUB) && console.log('useEffect: resetAction...')
  }, [trecoreData])

  const routeChange = () => {
    let path = `/Workspaces/${params.id}/workspace-services/${params.rid}/createvm`;
    navigate(path);
  }

  const VMCreateCheckStatus = async() => {        
    if(appRolesCtx.roles?.includes(RoleName.TREAdmin)) {
      setVMCreate(true);
      //yn(process.env.REACT_APP_DEBUG) && console.info('Executing isEnableVMCreate.1..', isVMCreate);
    } else if(filterData.length === 0 && ((workspaceCtx.roles?.includes(WorkspaceRoleName.AirlockManager)))) {
      setVMCreate(true);
      //yn(process.env.REACT_APP_DEBUG) && console.info('Executing isEnableVMCreate.2..', isVMCreate);
    } else if(filterData.length === 0 && ((workspaceCtx.roles?.includes(WorkspaceRoleName.WorkspaceResearcher)))) {
      setVMCreate(true);
      //yn(process.env.REACT_APP_DEBUG) && console.info('Executing isEnableVMCreate.3..', isVMCreate);
    } else {
      setVMCreate(false);
      //yn(process.env.REACT_APP_DEBUG) && console.info('Executing isEnableVMCreate.4..', isVMCreate);
    }
  }

  useEffect(() => {
    VMCreateCheckStatus();
  }, [isVMCreate, workspaceCtx, appRolesCtx]);

  const onAction = (e: string, resource: any) => {
    if (e === null) return false;
    if (e === "Stop") {
      return (
        executeAction(`stop`, resource.resourcePath),
        setIsVMBeingUpdated(true),
        setUpdateVMStatus(resource.id),
        setAction("stop")
      )
    } else if (e === "Start") {
      return (
        executeAction(`start`, resource.resourcePath),
        setIsVMBeingUpdated(true),
        setUpdateVMStatus(resource.id),
        setAction("start")
      )
    }
  }

  const onSort = (e: string) => {
    setSort(e);
    if (e === null) {
      return setFilterData(allFilterData);
    } else if (e === "Active") {
      setFilterData(allFilterData.filter((item: any) => item.azureStatus.powerState === VMPowerStates.Running || item.azureStatus.powerState === VMPowerStates.Starting));
    } else {
      setFilterData(allFilterData.filter((item: any) => item.deploymentStatus === "deployment_failed" || item.azureStatus.powerState === VMPowerStates.Deallocated || item.azureStatus.powerState === VMPowerStates.Stopped || item.azureStatus.powerState === VMPowerStates.Stopping || item.azureStatus.powerState === VMPowerStates.Deallocating || item.azureStatus.powerState === undefined));
    }
  }

  const getStatus = (id: string) => {
    const updatedVM: any = filterData.filter((item: any) => item.id === id);

    if (updatedVM[0].deploymentStatus === 'invoking_action') {
      return `is being ${action === "stop" ? "stopped" : "started"}`;
    } else if  (updatedVM[0].deploymentStatus === 'action_succeeded') {
      return `${action === "stop" ? "is now stopped" : "has now started"}`;
    }
  }

  return (
    <>
      {isVMBeingUpdated && updateVMStatus && (
        <div className="workspace-user-resources-data__updating-VM">
          <p className="workspace-user-resources-data__update-VM-text">Your VM: <span className="workspace-user-resources-data__update-VM-text--bold">{getStatus(updateVMStatus)}</span></p>
        </div>
      )}
      <section className="workspace-user-resources-data">
        <Title>List of VMs</Title>
        <Subtitle>{workspaceCtx.workspace.properties.display_name}</Subtitle>
        <article className="workspace-user-resources-data__header-bar">
          <div>
            <SmallTitle>Workspace owner</SmallTitle>
            {workspaceOwner && <Tag className="workspace-user-resources-data__tag" backgroundColor="#f7d7e6" color="#80224d">{workspaceOwner}</Tag>}
          </div>
          {(appRolesCtx.roles?.includes(RoleName.TREAdmin)) && (
            <div>
              <SmallTitle>Admin</SmallTitle>
              <TextLink to={`/manage-workspaces/${workspaceCtx.workspace.id}`}>Manage users</TextLink>
            </div>
          )}
          <div>
            <SmallTitle>Airlock</SmallTitle>
            <TextLink to={`/Workspaces/${workspaceCtx.workspace.id}/airlocks`}>Go to airlock dashboard</TextLink>
          </div>
        </article>
        <article className="workspace-user-resources-data__large-column">
          {serviceUpdating ? (
            <Lede className="workspace-user-resources-data__notification">Service is updating</Lede>
          ) : (
            <>
              <NumberDisplay resources={filterData} />
              {!isWorkspaceEnabled && <Lede className="workspace-user-resources-data__notification">This workspace is currently paused</Lede>}
              {isParentServicePaused ? (
                <Lede className="workspace-user-resources-data__notification">The guacamole workspace service is currently paused</Lede>
              ) : (
                <header className="workspace-user-resources-data__header">
                  <Selectbox
                    label="Filter by"
                    options={[
                      {
                        name: "Active",
                        value: "Active"
                      },
                      {
                        name: "Inactive",
                        value: "Inactive"
                      }
                    ]}
                    onChange={(e: any) => onSort(e)}
                  />
                  {isVMCreate && !isParentServicePaused && isWorkspaceEnabled && onlineCtx.isOnline && (
                    <Button
                      buttonColour="#3f78c0"
                      className="workspace-services__create-new"
                      icon={<ButtonArrow />}
                      onClick={() => routeChange()}
                      start
                    >
                      Create new
                    </Button>
                  )}
                </header>
              )}
              {filterData && (
                (!isParentServicePaused || isWorkspaceEnabled) ? (
                  <TRECoreWorkspaceDetailData
                    filterData={filterData}
                    onAction={onAction}
                    isActionInvoked={isActionInvoked}
                    setRefresh={setRefresh}
                    updateVMStatus={updateVMStatus}
                    sort={sort}
                    onSort={onSort}
                  />
                ) : (
                  (!isParentServicePaused || !isWorkspaceEnabled) && (
                    (appRolesCtx.roles?.includes(WorkspaceRoleName.WorkspaceOwner) || appRolesCtx.roles?.includes(RoleName.TREAdmin)) && (
                      <TRECoreWorkspaceDetailData
                        filterData={filterData}
                        onAction={onAction}
                        isActionInvoked={isActionInvoked}
                        setRefresh={setRefresh}
                        updateVMStatus={updateVMStatus}
                        sort={sort}
                        onSort={onSort}
                      />
                    )
                  )
                )
              )}
              {isLoadingAction && <p><i>Please wait while initiatizing to Execute Action...</i></p>}
            </>
          )}
        </article>
      </section>
    </>
  );
};

const TRECoreWorkspaceDetailData = ({ filterData, onAction, isActionInvoked, setRefresh, updateVMStatus, sort, onSort }: any) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  useEffect(() => {
    setData(filterData.slice(indexOfFirstRecord, indexOfLastRecord));
  }, [filterData, currentPage, updateVMStatus]);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const nPages = filterData && Math.ceil(filterData.length / recordsPerPage);

  const TableHeaders = [
    {
      header: "VM name"
    },
    {
      header: "User",
    },
    {
      header: "Current status"
    },
    {
      header: "Actions",
      colSpan: 3
    }
  ]

  return (
    <>
      <table className="workspace-user-resources-data__table">
        <TableHead headers={TableHeaders} />
        <tbody>
          {data && data.length > 0 && data.map((resource:any) => (
            <VmRow
              key={resource.id}
              resource={resource}
              setRefresh={setRefresh}
              onAction={onAction}
              sort={sort}
              onSort={onSort}
              isActionInvoked={isActionInvoked}
            />
          ))}
        </tbody>
      </table>
      {nPages > 1 && (
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
}

const VmRow = ({ resource, setRefresh, onAction, sort, onSort, isActionInvoked }: any) => {
  const { instance, accounts } = useMsal();
  const workspaceCtx = useContext(WorkspaceContext);
  const [operations, setOperations] = useState<any>();
  const [updating, setUpdating] = useState(false);
  const [vmSizeUpdated, setVmSizeUpdated] = useState(false);

  const callOperations = () => {
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
    }).then(async (response: any) => {
      await CallApiWithToken(
        response.accessToken,
        `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${workspaceCtx.workspace.id}/${ApiEndpoint.WorkspaceServices}/${resource.parentWorkspaceServiceId}/${ApiEndpoint.UserResources}/${resource.id}/${ApiEndpoint.Operations}`,
        HttpMethod.Get,
        ''
      ).then(response => {
        setOperations(response.operations);
        CheckOps(resource, response.operations, setUpdating, setRefresh, updating);
      })
    })
  }

  useEffect(() => {
    workspaceCtx.workspace.properties && callOperations();
  }, [workspaceCtx, vmSizeUpdated, isActionInvoked]);

  useEffect(() => {
    sort && onSort(sort);
  }, [updating]);

  return (
    <VmRowItem
      resource={resource}
      updating={updating}
      callOperations={callOperations}
      onAction={onAction}
      setVmSizeUpdated={setVmSizeUpdated}
    />
  )
}

const VmModal = ({ resource, setModalOpen, setVmSizeUpdated }: any) => {
  const [initialVmSize, setInitialVmSize] = useState(resource.properties.vm_size ? resource.properties.vm_size : "4 CPU | 32GB RAM");
  const [vmSize, setVmSize] = useState(resource.properties.vm_size ? resource.properties.vm_size : "4 CPU | 32GB RAM");
  const [errors, setErrors] = useState<null | error[]>(null);
  const [apiError, setApiError] = useState(null);
  const [apiLoading, setApiLoading] = useState(false);
  const [requestSuccessful, setRequestSuccessful] = useState<null | string>(null);
  const resourceVmSizeInputRef = useRef<HTMLInputElement>(null);
  const workspaceCtx = useContext(WorkspaceContext);
  const { instance, accounts } = useMsal();
  const location = useParams();
  const workspaceServiceId = location.rid;

  const makeApiCall = async (payload: any, workspace: any) => {
    const tokenRequest = {
      scopes: [`${workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`],
      account: accounts[0]
    }

    setApiError(null);
    setApiLoading(true);

    instance.acquireTokenSilent(tokenRequest).then(async (response) => {
      await CallApiWithToken(
        response.accessToken,
        `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${workspace.id}/${ApiEndpoint.WorkspaceServices}/${workspaceServiceId}/${ApiEndpoint.UserResources}/${resource.id}`,
        HttpMethod.Patch,
        payload,
        "*"
      ).then(response => {
        setRequestSuccessful(response.operation.message);
        setVmSizeUpdated(true);
        setApiLoading(false);
      }).catch((err: any) => {
        setApiError(err.message);
        setApiLoading(false);
      }).catch((err: any) => {
        setApiError(err.message);
        setApiLoading(false);
      })
      yn(process.env.REACT_APP_DEBUG) && console.log("User resource update request submitted");
    })
  }

  const createPayload = () => {
    const resourceVmSize = resourceVmSizeInputRef.current?.value;
    let properties: UpdateWorkspaceServiceRequest["properties"] = {}

    properties = {
      vm_size: resourceVmSize
    };

    const newUpdateWorkspaceServiceRequest : UpdateWorkspaceServiceRequest = {
      properties: properties
    };

    resourceVmSize === resource.properties.vm_size ? (
      setRequestSuccessful("VM is already this size")
    ) : (
      makeApiCall(newUpdateWorkspaceServiceRequest, workspaceCtx.workspace)
    )
  }

  const hasError = (formItem: string) => {
    const checkError = (obj: error) => obj.targetName === formItem;
    return errors && errors.some(checkError);
  }

  const onSubmit = () => {
    setErrors(null);
    const newErrors = [];
    const resourceVmSize = resourceVmSizeInputRef.current?.value;
    if (resourceVmSize === "Select") {
      newErrors.push({
        targetName: "vm-size",
        text: "Select a VM size. Default size is 4 CPU | 32GB RAM"
      })
    }

    return newErrors.length > 0 ? (
      setErrors(newErrors)
    ) : (
      createPayload()
    )
  }

  return (
    <LoadingBox loading={apiLoading}>
      <Modal
        action="Update"
        copy={`Select a VM size`}
        header="Update VM"
        options={
          <>
            {(apiError || requestSuccessful) && (
              <NotificationBox
                id="notification"
                error={apiError}
                text={`${requestSuccessful}. You can close this modal.`}
              />
            )}
            {errors && (
              <ErrorSummary
                errors={errors}
                heading="There is a problem submitting your request"
                onHandleErrorClick={(e: any) => document.getElementById(e)?.scrollIntoView()}
              />
            )}
            <Selectbox
              className="workspace-user-resources-data__select"
              id="vm-size"
              label="VM size"
              forwardRef={resourceVmSizeInputRef}
              defaultValue={vmSize}
              error={hasError("vm-size")}
              errorText="Select a VM size. Default size is 4 CPU | 32GB RAM"
              hint="Select the size of the VM"
              options={[
                {
                  name: "4 CPU | 32GB RAM",
                  value: "4 CPU | 32GB RAM"
                },
                {
                  name: "4 CPU | 64GB RAM",
                  value: "4 CPU | 64GB RAM"
                }
              ]}
              onChange={(e: string) => setVmSize(e)}
            />
          </>
        }
        onAction={() => onSubmit()}
        onExit={() => setModalOpen(false)}
        title={`Update the size of ${resource.properties.display_name} VM`}
        warning={initialVmSize === "4 CPU | 32GB RAM" && vmSize === "4 CPU | 64GB RAM" && (
          <p className="workspace-user-resources-data__warning-text">Your current VM is charged at £0.60/hour for using 32GB. 64GB VMs are charged at £0.90/hour. (This charge will be drawn down from your VM usage allocation)</p>
        )}
      />
    </LoadingBox>
  )
}

const VmRowItem = ({resource, updating, callOperations, onAction, setVmSizeUpdated}: any) => {
  const [showDetails, setShowDetails] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  useInterval(() => callOperations(), updating ? 10000 : null);
  const workspaceCtx = useContext(WorkspaceContext);
  const onlineCtx = useContext(IsOnlineContext);

  return (
    <>
      {modalOpen && (
        <VmModal resource={resource} setModalOpen={setModalOpen} setVmSizeUpdated={setVmSizeUpdated} />
      )}
      <tr key={resource.id}>
        <Td className={`${showDetails ? ' workspace-user-resources-data__table-cell' : ""}`}>
          <ToggleButton onClick={() => setShowDetails(!showDetails)}>{resource.properties.display_name}</ToggleButton>
        </Td>       
        <Td className={`${showDetails ? ' workspace-user-resources-data__table-cell' : ""}`}>{resource.user.name}</Td>
        <Td className={`${showDetails ? ' workspace-user-resources-data__table-cell' : ""}`}>
          {updating ? (
            "loading..."
          ) : (
            (() => {
              if (resource.azureStatus.powerState === VMPowerStates.Running) {
                return <p style={{color:'#00703c', marginBottom: '0'}}><b>Running</b></p>
              } else if(resource.azureStatus.powerState === VMPowerStates.Deallocated) {
                return <p style={{color:'#f47738', marginBottom: '0'}}><b>Deallocated</b></p>
              } else if(resource.deploymentStatus === "deployment_failed") {
                return <Tag backgroundColor="#eeefef" color="#383f43">Deployment failed</Tag>
              } else {
              return <p style={{color:'#85994b', marginBottom: '0'}}><b><i>{resource.azureStatus.powerState}...</i></b></p>
              }
            })()
          )}
        </Td>
        <Td className={`${showDetails ? ' workspace-user-resources-data__table-cell' : ""}`}>
          {onlineCtx.isOnline && (
            updating ? (
              "loading..."
            ) : (
              <Selectbox
                label={resource.azureStatus.powerState === VMPowerStates.Running ? "Stop virtual machine" : "Start virtual machine"}
                options={[
                  {
                    name: resource.azureStatus.powerState === VMPowerStates.Running ? "Stop" : "Start",
                    value: resource.azureStatus.powerState === VMPowerStates.Running ? "Stop" : "Start"
                  }
                ]}
                onChange={(e: any) => onAction(e, resource)}
                hiddenLabel
              />
            )
          )}
        </Td>
        <Td className={`${showDetails ? ' workspace-user-resources-data__table-cell' : ""}`}>
          {onlineCtx.isOnline && (
            updating ? (
              "loading..."
            ) : (
              workspaceCtx.workspace.isEnabled && resource.azureStatus.powerState === VMPowerStates.Running ? <VM trecoreData={resource.properties.connection_uri} /> : null
            )
          )}
        </Td>
        {workspaceCtx.roles?.includes(WorkspaceRoleName.WorkspaceOwner) && (
          <Td className={`${showDetails ? ' workspace-user-resources-data__table-cell' : ""}`}>
            {onlineCtx.isOnline && (
              updating ? (
                "loading..."
              ) : (
                <Button
                  className="workspace-user-resources-data__vm-button"
                  buttonColour="#3f78c0"
                  onClick={() => setModalOpen(true)}
                >
                  Update VM size
                </Button>
              )
            )}
          </Td>
        )}
      </tr>
      {showDetails && (
        <>
          <tr>
            <Td className="workspace-user-resources-data__table-cell" colspan={6}>
              <span>ID: </span>
              <span className="workspace-user-resources-data__grey">{resource.id}</span>
            </Td>
          </tr>
          <tr className="workspace-user-resources-data__table-row">
            <Td className="govuk-table__cell" colspan={6}>
              <span>Template name: </span>
              <span className="workspace-user-resources-data__grey">{resource.templateName}</span>
            </Td>
          </tr>
        </>
      )}
    </>
  )
}

const NumberDisplay = ({ resources }: any) => {
  const getUsers = (resources: any) => {
    const allUsers: any[] = [];
    resources.map((item: any) => {
      allUsers.push(item.user);
    });
    const uniqueUsers = Array.from(new Set(allUsers.map(item => item.id))).map(id => {
      return allUsers.find(item => item.id === id)
    });

    return uniqueUsers.length;
  }

  const users = getUsers(resources);
  const activeVms = resources.filter((item: any) => item.azureStatus.powerState === VMPowerStates.Running || item.azureStatus.powerState === VMPowerStates.Starting);
  const inactiveVms = resources.filter((item: any) => item.deploymentStatus === "deployment_failed" || item.azureStatus.powerState === VMPowerStates.Deallocated || item.azureStatus.powerState === VMPowerStates.Stopped || item.azureStatus.powerState === VMPowerStates.Stopping || item.azureStatus.powerState === VMPowerStates.Deallocating || item.azureStatus.powerState === undefined);

  return (
    <article className="number-display">
      <p className="number-display__figure">{users ? users : '--'} <span className="number-display__figure-text">Users</span></p>
      <p className="number-display__figure">{activeVms ? activeVms.length : '--'} <span className="number-display__figure-text">Active VMs</span></p>
      <p className="number-display__figure">{inactiveVms ? inactiveVms.length : '--'} <span className="number-display__figure-text">Inactive VMs</span></p>
    </article>
  )
}
