import React, { useCallback, useEffect, useRef, useState  } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { AirlockRequestType, AirlockReview, NewAirlockRequest } from '../models/airlock';
import { LabelText, HintText, ErrorText, ErrorSummary, Button } from 'govuk-react';

import { Textarea } from '../ui/Textarea';
import { FormWrapper } from '../ui/FormWrapper';
import { FormLabel } from '../ui/FormLabel';
import { FormButtonContainer } from '../ui/FormButtonContainer';
import { CancelButton } from '../ui/CancelButton';
import { RadioButton } from '../ui/RadioButton';
import { Heading } from '../ui/Heading';
import { Fieldset } from '../ui/Fieldset';

import { error } from '../../types';

{/*https://bobbyhadz.com/blog/react-unable-to-type-in-input */}

export const AirlockReviewRequestForm = (props:any) => {
  let wsId = useParams();
  let navigate = useNavigate();        
  const decisionExplanationInputRef = useRef<HTMLTextAreaElement>(null);
  const [requestDecision, setRequestDecision] = useState<null | string>(null);
  const [errors, setErrors] = useState<null | error[]>(null);

  const hasError = (formItem: string) => {
    const checkError = (obj: error) => obj.targetName === formItem;
    return errors && errors.some(checkError);
  }

  const ReviewRequestHandler = (event:any) => {
    event.preventDefault();
    setErrors(null);
    const newErrors = [];

    const explanation = decisionExplanationInputRef.current!.value;
    if (requestDecision === "False" && (!explanation || (explanation.length < 10 || explanation.length > 2000))) {
      newErrors.push({
        targetName: "explanation",
        text: "Enter a valid comment, minimum of 10 characters and maximum of 2000"
      })
    }

    requestDecision === null && (
      newErrors.push({
        targetName: "request-decision",
        text: "Choose to approve or reject the request"
      })
    )

    const review = {
      approval: requestDecision,
      decisionExplanation: explanation
    };

    return newErrors.length > 0 ? (
      setErrors(newErrors),
      document.getElementById("review-request-form")?.scrollIntoView()
    ) : (
      props.onAddReviewRequest(review)
    );
  };

  const cancelAction = () => { 
    let path = '/workspaces/' + wsId.id + '/airlocks';
    navigate(path);
  }

  return (
    <div id="review-request-form">
      {errors && errors.length > 0 && (
        <ErrorSummary
          errors={errors}
          heading="There is a problem submitting your request"
          onHandleErrorClick={(e: any) => document.getElementById(e)?.scrollIntoView()}
        />
      )}
      <form onSubmit={ReviewRequestHandler}>
        {props.fileDownloaded && (
          <FormWrapper>
            <Heading>What would you like to do now?</Heading>
            <Fieldset className="Airlock__fieldset" id="request-decision" error={hasError("request-decision")}>
              {hasError("request-decision") && <ErrorText className="Airlock__error-text">Choose to approve or reject the request</ErrorText>}
              <RadioButton
                label="Approve"
                checked={requestDecision === "True"}
                onChange={() => setRequestDecision("True")}
              />
              <RadioButton
                label="Reject"
                checked={requestDecision === "False"}
                onChange={() => setRequestDecision("False")}
              />
            </Fieldset>
            <FormLabel error={hasError("explanation")} id="explanation">
              <LabelText>Reason for decision</LabelText>
              <HintText>Use this section to provide feedback to the requester.</HintText>
              {hasError("explanation") && <ErrorText>Enter a valid comment (maximum 2000 characters)</ErrorText>}
              <Textarea forwardRef={decisionExplanationInputRef} />
            </FormLabel>
            <FormButtonContainer>
              <Button type="submit" className="govuk-button" data-module="govuk-button">Save and continue</Button>
              <CancelButton onClick={() => cancelAction()}>Cancel</CancelButton>
            </FormButtonContainer>
          </FormWrapper>
        )}
      </form>
    </div>
  );
}
