import React, { useRef, useState, useContext, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { ErrorSummary, ErrorText, Heading, HintText, LabelText, Input, Button, LoadingBox, UnorderedList, ListItem } from "govuk-react";
import { useMsal } from "@azure/msal-react";

import { WorkspaceContext } from "../../contexts/WorkspaceContext";
import { error } from "../../types";

import { FormWrapper } from "../ui/FormWrapper";
import { FormLabel } from "../ui/FormLabel";
import { Textarea } from "../ui/Textarea";
import { FormButtonContainer } from "../ui/FormButtonContainer";

import { AirlockRequestType } from "../models/airlock";
import { graphConfig, loginRequest, trecoreServicesConfig } from "../Core/authConfig";
import { CallApiWithToken, HttpMethod } from "../Core/fetch";
import { ApiEndpoint } from "../models/apiEndPoints";
import { MessageCard } from "../Error/MessageCard";

import "./AirlockCreate.css";
import uploadFileToBlob, { checkBlobsInContainer } from "../Core/azureStorageBlob";
import { Lede } from "../ui/Lede";
import { Checkbox } from "../ui/Checkbox";
import { IsOnlineContext } from "../../contexts/IsOnlineContext";
import { CharacterCount } from "../../services/CharacterCount";

type AirlockCreateProps = {
  type: string;
}

export const AirlockCreate = ({ type }: AirlockCreateProps) => {
  const location = useLocation();
  const stateData: any = location.state;
  const [errors, setErrors] = useState<null | error[]>(null);
  const [errorData, setErrorData] = useState(null);
  const [incorrectFileType, setIncorrectFileType] = useState<null | boolean>(null);
  const [selectedFile, setSelectedFile] = useState(stateData ? stateData.selectedFile : null);
  const [userName, setUserName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [arId, setArId] = useState(null);
  const [acceptedTerms, setAcceptedTerms] = useState(stateData ? stateData.stateObj.isEUUAAccepted : false);
  const wsId = useParams();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const workspaceCtx = useContext(WorkspaceContext);
  const isOnlineCtx = useContext(IsOnlineContext);
  const requestNameInputRef = useRef<HTMLInputElement>(null);
  const businessJustificationInputRef = useRef<HTMLTextAreaElement>(null);
  const fileUploadInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    }).then(async (res) => {
      await CallApiWithToken(
        res.accessToken,
        graphConfig.graphMeEndpoint,
        HttpMethod.Get,
        ''
      ).then(async (response) => {
        setUserName(response.displayName);
      })
    })
  }, []);

  const hasError = (formItem: string) => {
    const checkError = (obj: error) => obj.targetName === formItem;
    return errors && errors.some(checkError);
  }

  const allowedExtensions = [".ado", ".bmp", ".csv", ".do", ".dta", ".gif", ".gph", ".gz", ".ipynb", ".jpeg", ".jpg", ".json", ".log", ".mata", ".md", ".notebook", ".pdf", ".pkg", ".png", ".py", ".py3", ".r", ".rda", ".rdata", ".rds", ".rmd", ".rst", ".smcl", ".sql", ".sthlp", ".svg", ".tar", ".tar.gz", ".tex", ".toc", ".tsv", ".txt", ".xls", ".xlsm", ".xlsx", ".zip", ".ppb", ".pmb"];

  const checkFileType = (e: React.BaseSyntheticEvent) => {
    if (e.target.value.length > 0) {
      const string = e.target.value;
      const subString = string.substring(string.lastIndexOf(".") - 0).toLowerCase();
      const newFileName = `${string.substring(0, string.lastIndexOf("."))}${subString}`;
      const removeStart = newFileName.substring(0, newFileName.lastIndexOf("/") - 0);
      const checkExtension = allowedExtensions.findIndex(element => element.includes(subString));
      
      const file = new File([], removeStart, {
        type: e.target.files[0].type,
        lastModified: e.target.files[0].lastModified,
      });

      console.log("file: ", file);

      setIncorrectFileType(false);

      if (checkExtension === -1) {
        // not a valid file type
        setIncorrectFileType(true);
        setSelectedFile(null);
      } else {
        // valid file type
        setIncorrectFileType(false);
        setSelectedFile(e.target.files[0]);
      }
    }
  }

  const saveToState = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const stateObj = {
      businessJustification: businessJustificationInputRef.current!.value,
      isEUUAAccepted: type === "export" ? true : acceptedTerms,
      type: type,
      status: "draft",
      title: requestNameInputRef.current!.value,
      createdWhen: new Date().toLocaleDateString(),
      createdBy: {
        name: userName
      }
    }

    navigate(`/workspaces/${wsId.id}/airlocks/airlock-import-submit/import-1`, {state: { stateObj: stateObj, selectedFile: selectedFile }})
  }

  const createPayload = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);

    const enteredRequestTitle = requestNameInputRef.current!.value;
    const enteredBusinessJustification = businessJustificationInputRef.current!.value;

    // create payload with name and business justification
    const payload: any = {
      type: type === "export" ? AirlockRequestType.Export : AirlockRequestType.Import,
      title: enteredRequestTitle,
      businessJustification: enteredBusinessJustification,
      isEUUAAccepted: type === "export" ? true : acceptedTerms
    }

    console.log("selectedFile: ", selectedFile);

    // call API to create airlock request with name and business justification only
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
    }).then(async (initialResponse) => {
      await CallApiWithToken(
        initialResponse.accessToken,
        `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${wsId.id}/${ApiEndpoint.AirlockRequests}`,
        HttpMethod.Post,
        payload
      ).then(async (response) => {
        // retrieve airlock request id from response, setArId
        console.log("airlock request created");
        setArId(response.airlockRequest.id);
        // get sasToken for upload
        await CallApiWithToken(
          initialResponse.accessToken,
          `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${wsId.id}/${ApiEndpoint.AirlockRequests}/${response.airlockRequest.id}/${ApiEndpoint.AirlockLink}`,
          HttpMethod.Get,
          null
        ).then(async (sasTokenResponse) => {
          // upload file
          console.log("sasToken created");
          // sasTokenResponse.containerUrl
          await uploadFileToBlob(
            selectedFile,
            sasTokenResponse.containerUrl.replace(response.airlockRequest.id,''),
            response.airlockRequest.id
          ).then(async (fileRresponse) => {
            // check file has been uploaded
            console.log("check file has been upload");
            await checkBlobsInContainer(
              sasTokenResponse.containerUrl.replace(response.airlockRequest.id,''),
              response.airlockRequest.id
            ).then(async (checkBlobResponse) => {
              // successful
              console.log("successful");
              type === "export" ? (
                navigate(`/workspaces/${wsId.id}/airlocks/airlock-export-triage-stage-1/${response.airlockRequest.id}`, {state: { selectedFile: selectedFile }})
              ) : (
                navigate(`/workspaces/${wsId.id}/airlocks/airlock-import-submit/${response.airlockRequest.id}`, {state: { stateObj: response.airlockRequest, selectedFile: selectedFile }})
              );
            }).catch((err: any) => {
              setErrorData(err);
              setLoading(false);
            })
          }).catch((err: any) => {
            setErrorData(err);
            setLoading(false);
          })
        }).catch((err: any) => {
          setErrorData(err);
          setLoading(false);
        })
      }).catch((err: any) => {
        setErrorData(err);
        console.log("err: ", err);
        setLoading(false);
      })
    })
  }

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    setErrors(null);
    const newErrors = [];

    const inputRegex = /^[a-zA-Z0-9%&()\-_=+:';,.?\s]*$/;
    const newLineRegex = /\r?\n/g;

    const name = requestNameInputRef.current?.value.replace(newLineRegex, " ");
    if (!name || (name.length < 5 || name.length > 50) || !name.match(inputRegex)) {
      newErrors.push({
        targetName: "request-name",
        text: "Enter a valid Request name"
      })
    }

    const reason = businessJustificationInputRef.current?.value.replace(newLineRegex, " ");
    console.log("reason: ", reason);
    if (!reason || (reason.length < 11 || reason.length > 200) || !reason.match(inputRegex)) {
      newErrors.push({
        targetName: "request-justification",
        text: "Enter a valid Request justification"
      })
    }

    const file = fileUploadInputRef.current?.value;
    if (!file || incorrectFileType) {
      newErrors.push({
        targetName: "file-upload",
        text: "Upload a valid file"
      })
    }

    return newErrors.length > 0 ? (
      setErrors(newErrors),
      document.getElementById("error-boundary")?.scrollIntoView()
    ) : type === "export" ? createPayload(e) : saveToState(e);
  }

  // add in check for maximum of 10 export requests
  return (
    <LoadingBox loading={loading}>
      {(isOnlineCtx.isOnline && type === "export") || (!isOnlineCtx.isOnline && type === "import") ? (
        <>
          {type === "export" ? (
            <Lede>Export function is only available in your CPRD Safe workspace</Lede>
          ) : (
            <Lede>Import function is only available outside your CPRD Safe workspace</Lede>
          )}
        </>
      ) : (
        <>
          {errorData && (
            <MessageCard msgData={errorData} />
          )}
          <div id="error-boundary">
            {errors && errors.length > 0 && (
              <ErrorSummary
                errors={errors}
                heading="There is a problem"
                onHandleErrorClick={(e: any) => document.getElementById(e)?.scrollIntoView()}
              />
            )}
          </div>
          {type === "export" && <Heading as="h1" size={27}>Workspace name: {workspaceCtx.workspace.properties && workspaceCtx.workspace.properties.display_name}</Heading>}
          {type === "export" && <Heading as="h2" size={19}>New export request: output declaration form</Heading>}
          <Heading as={type === "export" ? "h2" : "h1"} size="SMALL">
            {type === "export" ? (
              "New export request 1/3"
            ) : (
              "New import request"
            )}
          </Heading>
          {type === "export" ? (
            <>
              <Heading as="h2" size={36}>Setup information</Heading>
              <AirlockForm
                businessJustificationInputRef={businessJustificationInputRef}
                checkFileType={checkFileType}
                fileUploadInputRef={fileUploadInputRef}
                hasError={hasError}
                incorrectFileType={incorrectFileType}
                onSubmit={onSubmit}
                requestNameInputRef={requestNameInputRef}
                wsId={wsId.id}
                type={type}
              />
            </>
          ) : (
            <>
              {acceptedTerms ? (
                <>
                  <Heading as="h2" size={36}>Setup information</Heading>
                  <AirlockForm
                    businessJustificationInputRef={businessJustificationInputRef}
                    checkFileType={checkFileType}
                    fileUploadInputRef={fileUploadInputRef}
                    hasError={hasError}
                    incorrectFileType={incorrectFileType}
                    onSubmit={onSubmit}
                    requestNameInputRef={requestNameInputRef}
                    wsId={wsId.id}
                    stateData={stateData}
                    type={type}
                  />
                </>
              ) : (
                <>
                  <Heading as="h2">Confirm acceptance</Heading>
                  <ImportTerms setTerms={setAcceptedTerms} wsId={wsId.id} />
                </>
              )}
            </>
          )}
        </>
      )}
    </LoadingBox>
  )
}

export const AirlockForm = ({
    onSubmit,
    hasError,
    requestNameInputRef,
    businessJustificationInputRef,
    incorrectFileType,
    checkFileType,
    fileUploadInputRef,
    wsId,
    stateData,
    type
  }: any) => {

  const [nameCharactersRemaining, setNameCharactersRemaining] = useState<number>(50);
  const [requestJustificationRemaining, setRequestJustificationRemaining] = useState<number>(200);
  const [fileUpdated, setFileUpdated] = useState(false);

  const addFile = (input: any) => {
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(stateData.selectedFile);
    return input.files = dataTransfer.files;
  }

  const fileChanged = (e: any) => {
    setFileUpdated(true);
    checkFileType(e);
  }

  useEffect(() => {
    // this is to add a file to input field if one has already been selected
    const fileInput: any = document.querySelector('input[type="file"]');
    stateData && fileInput && !fileUpdated && addFile(fileInput);
  });

  return (
    <form onSubmit={onSubmit}>
      <FormWrapper>
        <FormLabel error={hasError("request-name")} id="request-name">
          <LabelText>Request name</LabelText>
          {hasError("request-name") && <ErrorText>{(requestNameInputRef.current.value.length < 5 || requestNameInputRef.current.value.length > 50) ? "Request name must contain a minimum of 5 and maximum of 50 characters" : "Only the letters a to z, numbers 0 to 9, spaces and the characters %&()-_+=:;',.? are allowed"}</ErrorText>}
          <Input ref={requestNameInputRef} onChange={() => CharacterCount(requestNameInputRef.current.value.length, 50, setNameCharactersRemaining)} maxLength="50" defaultValue={stateData ? stateData.stateObj.title : ""} />
          <p>{nameCharactersRemaining} character(s) remaining</p>
          <HintText>Minimum 5 characters, maximum 50 characters including spaces. Only letters a to z, numbers 0 to 9, spaces and the following characters are allowed: %&()-_+=:;',.?</HintText>
        </FormLabel>
        <FormLabel error={hasError("request-justification")} id="request-justification">
          <LabelText>Request justification</LabelText>
          {hasError("request-justification") && <ErrorText>{(businessJustificationInputRef.current.value.length < 11 || businessJustificationInputRef.current.value.length > 200) ? "Request justification must contain a minimum of 10 and maximum of 200 characters" : "Only the letters a to z, numbers 0 to 9, spaces and the characters %&()-_+=:;',.? are allowed"}</ErrorText>}
          <Textarea forwardRef={businessJustificationInputRef} onChange={() => CharacterCount(businessJustificationInputRef.current.value.length, 200, setRequestJustificationRemaining)} maxLength="200" defaultValue={stateData ? stateData.stateObj.businessJustification : ""} />
          <p>{requestJustificationRemaining} character(s) remaining</p>
          <HintText>Minimum 10 characters, maximum 200 characters including spaces. Only letters a to z, numbers 0 to 9, spaces and the following characters are allowed: %&()-_+=:;',.?</HintText>
        </FormLabel>
        <FormLabel error={hasError("file-upload")} id="file-upload">
          <LabelText>Select your file to {type}</LabelText>
          {hasError("file-upload") && <ErrorText>Upload a file</ErrorText>}
          {incorrectFileType && <ErrorText>Upload a valid file type (.ado, .bmp, .csv, .do, .dta, .gif, .gph, .gz, .ipynb, .jpeg, .jpg, .json, .log, .mata, .md, .notebook, .pdf, .pkg, .pmb, .png, .ppb, .py, .py3, .r, .rda, .rdata, .rds, .rmd, .rst, .smcl, .sql, .sthlp, .svg, .tar, .tar.gz, .tex, .toc, .tsv, .txt, .xls, .xlsm, .xlsx, .zip)</ErrorText>}
          <input
            className="govuk-file-upload"
            id="file-upload-1"
            name="file-upload-1"
            type="file"
            onChange={(e) => fileChanged(e)}
            accept=".ado, .bmp, .csv, .do, .dta, .gif, .gph, .gz, .ipynb, .jpeg, .jpg, .json, .log, .mata, .md, .notebook, .pdf, .pkg, .png, .py, .py3, .r, .rda, .rdata, .rds, .rmd, .rst, .smcl, .sql, .sthlp, .svg, .tar, .tar.gz, .tex, .toc, .tsv, .txt, .xls, .xlsm, .xlsx, .zip, .ppb, .pmb"
            ref={fileUploadInputRef}
          />
          <HintText>Allowed file types: .ado, .bmp, .csv, .do, .dta, .gif, .gph, .gz, .ipynb, .jpeg, .jpg, .json, .log, .mata, .md, .notebook, .pdf, .pkg, .pmb, .png, .ppb, .py, .py3, .r, .rda, .rdata, .rds, .rmd, .rst, .smcl, .sql, .sthlp, .svg, .tar, .tar.gz, .tex, .toc, .tsv, .txt, .xls, .xlsm, .xlsx, .zip.</HintText>
        </FormLabel>
        <FormButtonContainer>
          <Button className="airlock-create__button govuk-button" type="submit">Next</Button>
        </FormButtonContainer>
      </FormWrapper>
    </form>
  )
}

export const ImportTerms = ({ setTerms, wsId }: any) => {
  const [agreedTerms, setAgreedTerms] = useState(false);
  const navigate = useNavigate();

  const backToDashboard = () => {
    navigate(`/workspaces/${wsId}/airlocks/`, {state: { message: "You must accept the terms in order to import files into CPRD Safe." }})
  }

  return (
    <>
      <p>In requesting to import data to CPRD Safe, I confirm the following:</p>
      <UnorderedList>
        <ListItem>The requested import is owned by my organisation, and I am authorised to upload the data on behalf of my organisation, or the import has otherwise been pre-approved by CPRD.</ListItem>
        <ListItem>The requested import does not include any of the following content:</ListItem>
        <UnorderedList>
          <ListItem>Files containing or suspected of containing malware</ListItem>
          <ListItem>Files containing patient or event-level data that have not been provided by CPRD</ListItem>
          <ListItem>Files containing personal data (as defined by applicable data protection laws)</ListItem>
          <ListItem>Files that present a risk of re-identification of CPRD Data if used in conjunction with the CPRD Dataset within <strong>CPRD Safe</strong> (unless the use of these files has been specifically approved as part of a protocol approved by CPRD in line with applicable policies and procedures).</ListItem>
        </UnorderedList>
      </UnorderedList>
      <Checkbox
        label="I accept"
        onChange={() => setAgreedTerms(!agreedTerms)}
      />
      <FormButtonContainer>
        <Button className="govuk-button" onClick={() => setTerms(true)} disabled={!agreedTerms}>Accept</Button>
        <Button className="govuk-button" onClick={() => backToDashboard()}>Decline</Button>
      </FormButtonContainer>
    </>
  )
}




