import React, { useState, useContext } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { Button, ErrorSummary, ErrorText, LoadingBox, Heading } from 'govuk-react';

import { RadioButton } from '../../../ui/RadioButton';
import { FormWrapper } from '../../../ui/FormWrapper';
import { Fieldset } from '../../../ui/Fieldset';

import { error } from '../../../../types';

import { loginRequest } from '../../../Core/authConfig';
import { WorkspaceContext } from '../../../../contexts/WorkspaceContext';
import { CallApiWithToken, HttpMethod } from '../../../Core/fetch';
import { ApiEndpoint } from '../../../models/apiEndPoints';
import { trecoreServicesConfig } from '../../../Core/authConfig';
import { MessageCard } from '../../../Error/MessageCard';
import { FormButtonContainer } from '../../../ui/FormButtonContainer';
import { IsOnlineContext } from '../../../../contexts/IsOnlineContext';
import { Lede } from '../../../ui/Lede';

import './index.css';

export const AirlockExportTriageStageOne = () => {
  const [rdgConsistent, setRdgConsistent] = useState<null | boolean>(null);
  const [patientLevelData, setPatientLevelData] = useState<null | boolean>(null);
  const [requestedOutputsClear, setRequestedOutputsClear] = useState<null | boolean>(null);
  const [requestedOutputsStatic, setRequestedOutputsStatic] = useState<null | boolean>(null);
  const [requestedOutputsPermittedFiles, setRequestedOutputsPermittedFiles] = useState<null | boolean>(null);
  const [hiddenInformation, setHiddenInformation] = useState<null | boolean>(null);
  const [errors, setErrors] = useState<null | error[]>(null);
  const [errorData, setErrorData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { instance, accounts } = useMsal();
  const workspaceCtx = useContext(WorkspaceContext);
  const isOnlineCtx = useContext(IsOnlineContext);
  const params = useParams();
  const wsId = params.id;
  const arId = params.aid;
  const location = useLocation();
  const state: any = location.state;
  const navigate = useNavigate();

  const createPayload = (e: any) => {
    e.preventDefault();

    const payload: any = {
      "rdgConsistent": rdgConsistent!.toString(),
      "patientLevelData": patientLevelData!.toString(),
      "requestedOutputsClear": requestedOutputsClear!.toString(),
      "requestedOutputsStatic": requestedOutputsStatic!.toString(),
      "requestedOutputsPermittedFiles": requestedOutputsPermittedFiles!.toString(),
      "hiddenInformation": hiddenInformation!.toString()
    }

    setLoading(true);
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
    }).then(async (response) => {
      await CallApiWithToken(
        response.accessToken,
        `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${wsId}/${ApiEndpoint.AirlockRequests}/${arId}/${ApiEndpoint.AirlockTriage}`,
        HttpMethod.Post,
        payload
      ).then(async (res) => {
        await CallApiWithToken(
          response.accessToken,
          `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${wsId}/${ApiEndpoint.AirlockRequests}/${arId}/${ApiEndpoint.AirlockExitTriage}`,
          HttpMethod.Post,
          ''
        ).then(response => {
          console.log("Is file added in as part of response? ", response);
          // or Navigate to the next page
          console.log("triageLevel: ", response.airlockRequest.triageLevel);
          if (response.airlockRequest.triageLevel === "L4a: Rejected" || response.airlockRequest.triageLevel === "L4: Rejected") {
            navigate(`/workspaces/${wsId}/airlocks/airlock-export-triage-rejection/${arId}`);
          } else {
            navigate(`/workspaces/${wsId}/airlocks/airlock-export-triage-stage-2/${arId}`, { state: { selectedFile: state.selectedFile }});
          }
          setLoading(false);
        }).catch((err: any) => {
          console.log("err: ", err);
          setErrorData(err);
          setLoading(false);
        })
      }).catch((err: any) => {
        console.log("err: ", err);
        setErrorData(err);
        setLoading(false);
      })
    })
  }

  const hasError = (formItem: string) => {
    const checkError = (obj: error) => obj.targetName === formItem;
    return errors && errors.some(checkError);
  }

  const onSubmit = (e: any) => {
    e.preventDefault();
    console.log("submit hit");
    setErrors(null);
    const newErrors = [];

    if (rdgConsistent === null) {
      newErrors.push({
        targetName: "rdgConsistent",
        text: "Select Yes or No"
      })
    }

    if (patientLevelData === null) {
      newErrors.push({
        targetName: "patientLevelData",
        text: "Select Yes or No"
      })
    }

    if (requestedOutputsClear === null) {
      newErrors.push({
        targetName: "requestedOutputsClear",
        text: "Select Yes or No"
      })
    }

    if (requestedOutputsStatic === null) {
      newErrors.push({
        targetName: "requestedOutputsStatic",
        text: "Select Yes or No"
      })
    }

    if (requestedOutputsPermittedFiles === null) {
      newErrors.push({
        targetName: "requestedOutputsPermittedFiles",
        text: "Select Yes or No"
      })
    }

    if (hiddenInformation === null) {
      newErrors.push({
        targetName: "hiddenInformation",
        text: "Select Yes or No"
      })
    }

    return newErrors.length > 0 ? (
      setErrors(newErrors),
      document.getElementById("error-boundary")?.scrollIntoView()
    ) : (
      createPayload(e)
    )
  };

  return (
    <LoadingBox loading={loading}>
      {isOnlineCtx.isOnline ? (
        <Lede>Export function is only available in your CPRD Safe workspace</Lede>
      ) : (
        <>
          {errorData && (
            <MessageCard msgData={errorData} />
          )}
          <Heading as="h1" size={27}>Workspace name: {workspaceCtx.workspace.properties && workspaceCtx.workspace.properties.display_name}</Heading>
          <Heading as="h1" size="SMALL">New export request 2/3</Heading>
          <Heading as="h2" size={36}>Safe Output criteria</Heading>
          <div id="error-boundary">
            {errors && errors.length > 0 && (
              <ErrorSummary
                errors={errors}
                heading="There is a problem"
                onHandleErrorClick={(e: any) => document.getElementById(e)?.scrollIntoView()}
              />
            )}
          </div>
          <form onSubmit={onSubmit}>
            <FormWrapper>
              <Heading as="h3" size="SMALL">Confirm the following:</Heading>
              <Fieldset id="rdgConsistent" error={hasError("rdgConsistent")}>
                <legend>
                  <p className="airlock-export-triage-stage-one__legend">Are the requested outputs in line with the RDG-approved protocol for this workspace?</p>
                </legend>
                {hasError("rdgConsistent") && <ErrorText>Make a selection</ErrorText>}
                <div className="airlock-export-triage-stage-one__radio-wrapper">
                  <RadioButton
                    label="Yes"
                    name="rdgConsistent-yes"
                    checked={rdgConsistent === true}
                    onChange={() => setRdgConsistent(true)}
                  />
                  <RadioButton
                    label="No"
                    name="rdgConsistent-no"
                    checked={rdgConsistent === false}
                    onChange={() => setRdgConsistent(false)}
                    />
                </div>
              </Fieldset>
              <Fieldset id="patientLevelData" error={hasError("patientLevelData")}>
                <legend>
                  <p className="airlock-export-triage-stage-one__legend">Do the requested outputs include any event or patient-level data?</p>
                </legend>
                {hasError("patientLevelData") && <ErrorText>Make a selection</ErrorText>}
                <div className="airlock-export-triage-stage-one__radio-wrapper">
                  <RadioButton
                    label="Yes"
                    checked={patientLevelData === true}
                    name="patientLevelData-yes"
                    onChange={() => setPatientLevelData(true)}
                  />
                  <RadioButton
                    label="No"
                    checked={patientLevelData === false}
                    name="patientLevelData-no"
                    onChange={() => setPatientLevelData(false)}
                  />
                </div>
              </Fieldset>
              <Fieldset id="requestedOutputsClear" error={hasError("requestedOutputsClear")}>
                <legend>
                  <p className="airlock-export-triage-stage-one__legend">Are all the requested outputs sufficiently clear and comprehensible to permit output checking without the need for dataset- or project-specific knowledge?</p>
                </legend>
                {hasError("requestedOutputsClear") && <ErrorText>Make a selection</ErrorText>}
                <div className="airlock-export-triage-stage-one__radio-wrapper">
                  <RadioButton
                    label="Yes"
                    checked={requestedOutputsClear === true}
                    name="requestedOutputsClear-yes"
                    onChange={() => setRequestedOutputsClear(true)}
                  />
                  <RadioButton
                    label="No"
                    checked={requestedOutputsClear === false}
                    name="requestedOutputsClear-no"
                    onChange={() => setRequestedOutputsClear(false)}
                  />
                </div>
              </Fieldset>
              <Fieldset id="requestedOutputsStatic" error={hasError("requestedOutputsStatic")}>
                <legend>
                  <p className="airlock-export-triage-stage-one__legend">Are all requested outputs static?</p>
                </legend>
                {hasError("requestedOutputsStatic") && <ErrorText>Make a selection</ErrorText>}
                <div className="airlock-export-triage-stage-one__radio-wrapper">
                  <RadioButton
                    label="Yes"
                    checked={requestedOutputsStatic === true}
                    name="requestedOutputsStatic-yes"
                    onChange={() => setRequestedOutputsStatic(true)}
                  />
                  <RadioButton
                    label="No"
                    checked={requestedOutputsStatic === false}
                    name="requestedOutputsStatic-no"
                    onChange={() => setRequestedOutputsStatic(false)}
                  />
                </div>
              </Fieldset>
              <Fieldset id="requestedOutputsPermittedFiles" error={hasError("requestedOutputsPermittedFiles")}>
                <legend>
                  <p className="airlock-export-triage-stage-one__legend">Do all requested outputs use permitted file types?</p>
                </legend>
                {hasError("requestedOutputsPermittedFiles") && <ErrorText>Make a selection</ErrorText>}
                <div className="airlock-export-triage-stage-one__radio-wrapper">
                  <RadioButton
                    label="Yes"
                    checked={requestedOutputsPermittedFiles === true}
                    name="requestedOutputsPermittedFiles-yes"
                    onChange={() => setRequestedOutputsPermittedFiles(true)}
                  />
                  <RadioButton
                    label="No"
                    checked={requestedOutputsPermittedFiles === false}
                    name="requestedOutputsPermittedFiles-no"
                    onChange={() => setRequestedOutputsPermittedFiles(false)}
                  />
                </div>
              </Fieldset>
              <Fieldset id="hiddenInformation" error={hasError("hiddenInformation")}>
                <legend>
                  <p className="airlock-export-triage-stage-one__legend">Is there any hidden information included (e.g., embedded files, comments, or tracked changes)?</p>
                </legend>
                {hasError("hiddenInformation") && <ErrorText>Make a selection</ErrorText>}
                <div className="airlock-export-triage-stage-one__radio-wrapper">
                  <RadioButton
                    label="Yes"
                    checked={hiddenInformation === true}
                    name="hiddenInformation-yes"
                    onChange={() => setHiddenInformation(true)}
                  />
                  <RadioButton
                    label="No"
                    checked={hiddenInformation === false}
                    name="hiddenInformation-no"
                    onChange={() => setHiddenInformation(false)}
                  />
                </div>
              </Fieldset>
              <FormButtonContainer>
                <Button type="submit" className="airlock-export-triage-stage-one__submit govuk-button">Save and next</Button>
              </FormButtonContainer>
            </FormWrapper>
          </form>
        </>
      )}
    </LoadingBox>
  )
}
