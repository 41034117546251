import React, { useRef, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { LabelText, HintText, Input, Button, ErrorText, ErrorSummary } from "govuk-react";
import { useNavigate } from 'react-router-dom';
import { NewWorkspaceRequest } from '../models/workspace';
import { EnvironmentsName } from '../models/roleNames';
import { MessageCard } from '../Error/MessageCard';
import { Textarea } from '../ui/Textarea';
import { FormWrapper } from '../ui/FormWrapper';
import { FormLabel } from '../ui/FormLabel';
import { FormButtonContainer } from '../ui/FormButtonContainer';
import { CancelButton } from '../ui/CancelButton';
import { Selectbox } from '../ui/Selectbox';

import { error } from '../../types';
import { loginRequest, trecoreConfig } from '../Core/authConfig';
import { CallApiWithToken, HttpMethod } from '../Core/fetch';
import { ApiEndpoint } from '../models/apiEndPoints';

import "./CreateWorkspaceForm.css";

export const CreateWorkspaceForm=(props: any)=>{    
  let navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [errors, setErrors] = useState<null | error[]>(null);
  const [errorData, setErrorData] = useState(null);
  const [templateName, setTemplateName] = useState<null | string>(null);
  const templateNameInputRef = useRef<HTMLInputElement>(null);
  const wsNameInputRef = useRef<HTMLInputElement>(null);
  const descriptionInputRef = useRef<HTMLTextAreaElement>(null);
  const airlockManagerEmailInputRef = useRef<HTMLInputElement>(null);

  const wsAuthTypeInputRef = useRef<HTMLInputElement>(null);
  const wsClientIdInputRef = useRef<any>('');
  const wsClientSecretInputRef = useRef<any>('');
  const wsAddressSpaceSizeInputRef = useRef<HTMLInputElement>(null);
  const wsTreUiFqdnInputRef = useRef<HTMLInputElement>(null);
  const wsSaVmTemplateFqdnInputRef = useRef<HTMLInputElement>(null);
  const wsArumDestinationAddressInputRef = useRef<any>('');
  let envInLower = process.env.REACT_APP_ENV?.toLowerCase();

  let fqdnDefault = "";
  if (envInLower === EnvironmentsName.TEST) {
    fqdnDefault = "test.";
  } else if (envInLower === EnvironmentsName.DEVELOPMENT) {
    fqdnDefault = "dev.";
  }

  const createTRECoreWorkspaceHandler = (event: any) => {
    event.preventDefault();
    const enteredtemplateName = templateNameInputRef.current!.value;
    const entereddescription = descriptionInputRef.current!.value;
    const enteredAirlockManagerEmail = airlockManagerEmailInputRef.current!.value;
    const enteredvmName = wsNameInputRef.current!.value;
    
    const enteredwsAuthTypeInputRef = wsAuthTypeInputRef.current!.value;
    const enteredwsClientIdInputRef = wsClientIdInputRef.current!.value;
    const enteredwsClientSecretInputRef = wsClientSecretInputRef.current!.value;
    const enteredwsAddressSpaceSizeInputRef = wsAddressSpaceSizeInputRef.current!.value;
    const enteredwsTreUiFqdnInputRef = wsTreUiFqdnInputRef.current!.value;
    const enteredwsSaVmTemplateFqdnInputRef = wsSaVmTemplateFqdnInputRef.current!.value;
    
    let properties: NewWorkspaceRequest["properties"] = {};
      
    properties = {
      display_name: enteredvmName,
      description: entereddescription,
      auth_type: enteredwsAuthTypeInputRef,
      address_space_size: enteredwsAddressSpaceSizeInputRef,
      tre_ui_fqdn: enteredwsTreUiFqdnInputRef,
      sa_vm_template_fqdn: enteredwsSaVmTemplateFqdnInputRef,
      create_aad_groups: true,
      airlock_manager_email: enteredAirlockManagerEmail,
      airlock_ws_name_suffix: process.env.REACT_APP_AIRLOCK_NAME_SUFFIX,
      core_sp_id: process.env.REACT_APP_CORE_REGISTRATION_OBJECT_ID,
      ux_app_registration_client_id_list: process.env.REACT_APP_UX_APP_REGISTRATION_CLIENT_ID_LIST,
      admin_consent_app_registration_client_id: process.env.REACT_APP_ADMIN_CONSENT_APP_REGISTRATION_CLIENT_ID,
      admin_consent_app_registration_secret: process.env.REACT_APP_ADMIN_CONSENT_APP_REGISTRATION_SECRET,
      shared_storage_quota: 5120
    };

    const addProperties = () => {
      if (envInLower !== EnvironmentsName.PRODUCTION) {
        return (
          properties.client_id = enteredwsClientIdInputRef,
          properties.client_secret = enteredwsClientSecretInputRef
        )
      }
    }

    const addNonSSLProperties = () => {
      if (templateName !== "tre-workspace-ssl") {
        if (envInLower !== EnvironmentsName.PRODUCTION) {
          return properties.sqlmi_fqdn = "sqlmi-aurum-dev.8df74733b712.database.windows.net"
        } else {
          return properties.sqlmi_fqdn = "int-res-prod-mssql-managed.3650bfa943a7.database.windows.net"
        }
      }
    }

    addProperties();
    addNonSSLProperties();

    const newWorkspaceRequest : NewWorkspaceRequest = {
      templateName: enteredtemplateName,
      properties: properties
    };
    props.onAddImportRequest(newWorkspaceRequest);
  };

  const routeChange = () => { 
    let path = `/workspaces/` ; 
    navigate(path);
  }

  const hasError = (formItem: string) => {
    const checkError = (obj: error) => obj.targetName === formItem;
    return errors && errors.some(checkError);
  }

  const onSubmit = (e: any) => {
    e.preventDefault();
    const emailRegEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setErrors(null);
    const newErrors = [];
    const templateName = templateNameInputRef.current?.value;
    if (!templateName || templateName === "Select") {
      newErrors.push({
        targetName: "template-name",
        text: "Select a template name"
      })
    }

    const workspaceName = wsNameInputRef.current?.value;
    if (!workspaceName || (workspaceName.length < 5 || workspaceName.length > 100)) {
      newErrors.push({
        targetName: "workspace-name",
        text: "Enter a valid workspace name, minimum of 5 characters and maximum of 100"
      })
    }

    const description = descriptionInputRef.current?.value;
    if (!description || (description.length < 10 || description.length > 100)) {
      newErrors.push({
        targetName: "description",
        text: "Enter a valid description, minimum of 10 characters and maximum 100"
      })
    }

    const airlockManagerEmail = airlockManagerEmailInputRef.current?.value;
    if (airlockManagerEmail && !airlockManagerEmail.match(emailRegEx)) {
      newErrors.push({
        targetName: "airlock-manager-email",
        text: "Enter valid email address"
      })
    }

    return newErrors.length > 0 ? (
      setErrors(newErrors),
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    ) : (
      checkDeployments(e)
    );
  }

  const checkDeployments = (e: any) => {
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: trecoreConfig.scopes
    }).then(async (response) => {
      await CallApiWithToken(
        response.accessToken,
        `${trecoreConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}`,
        HttpMethod.Get,
        ''
      ).then(response => {
        const deploying = response.workspaces.filter((item: any) => item.deploymentStatus === "deploying" || item.deploymentStatus === "awaiting_deployment");
        deploying.length > 0 ? (
          setErrors([{
            targetName: "",
            text: "A workspace is currently being deployed, you can create another workspace once this has completed"
          }])
        ) : (
          createTRECoreWorkspaceHandler(e)
        )
      }).catch((err: any) => {
        setErrorData(err);
      })
    })
  }

  return (
    <>
      {errorData && (
        <MessageCard msgData={errorData} />
      )}
      {errors && errors.length > 0 && (
        <ErrorSummary
          errors={errors}
          heading="There is a problem submitting your request"
          onHandleErrorClick={(e: any) => document.getElementById(e)?.scrollIntoView()}
        />
      )}
      <form onSubmit={onSubmit}>
        <FormWrapper>
          <Selectbox
            className="CreateWorkspaceForm__select"
            id="template-name"
            label="Template name"
            forwardRef={templateNameInputRef}
            error={hasError("template-name")}
            errorText="Select a template name"
            options={[
              {
                name: "tre-workspace-msl",
                value: "tre-workspace-msl"
              },
              {
                name: "tre-workspace-rdg",
                value: "tre-workspace-rdg"
              },
              {
                name: "tre-workspace-ssl",
                value: "tre-workspace-ssl"
              }
            ]}
            onChange={(e: string) => setTemplateName(e)}
          />
          <FormLabel error={hasError("workspace-name")} id="workspace-name">
            <LabelText>Workspace name</LabelText>
            <HintText>The name of the new workspace.</HintText>
            {hasError("workspace-name") && <ErrorText>Enter a valid workspace name</ErrorText>}
            <Input ref={wsNameInputRef} />
          </FormLabel>
          <FormLabel error={hasError("description")} id="description">
            <LabelText>Description</LabelText>
            <HintText>Description for the new workspace.</HintText>
            {hasError("description") && <ErrorText>Enter a valid description (maximum 100 characters)</ErrorText>}
            <Textarea forwardRef={descriptionInputRef} />
          </FormLabel>
          <FormLabel error={hasError("airlock-manager-email")} id="airlock-manager-email">
            <LabelText>Airlock manager email</LabelText>
            <HintText>The email address of your airlock manager.</HintText>
            {hasError("airlock-manager-email") && <ErrorText>Enter a valid email address</ErrorText>}
            <Input ref={airlockManagerEmailInputRef} />
          </FormLabel>
          <FormLabel>
            <LabelText>Address space size</LabelText>
            <HintText>Address space size.</HintText>
            <Input ref={wsAddressSpaceSizeInputRef} defaultValue="small" readOnly />
          </FormLabel>
          <FormLabel>
            <LabelText>TRE UI fqdn</LabelText>
            <HintText>TRE UI fqdn.</HintText>
            <Input ref={wsTreUiFqdnInputRef} defaultValue={`safe.${fqdnDefault}cprd.com`} readOnly />
          </FormLabel>
          <FormLabel>
            <LabelText>SA VM template fqdn</LabelText>
            <HintText>SA VM template fqdn.</HintText>
            <Input ref={wsSaVmTemplateFqdnInputRef} defaultValue={envInLower === EnvironmentsName.PRODUCTION ? "strgtremgmtprod.blob.core.windows.net" : "strgtremgmt.blob.core.windows.net"} readOnly />
          </FormLabel>
          {templateName !== "tre-workspace-ssl" && (
            <FormLabel>
              <LabelText>Arum destination fqdn</LabelText>
              <HintText>Arum destination fqdn.</HintText>
              <Input
                ref={wsArumDestinationAddressInputRef}
                defaultValue={envInLower === EnvironmentsName.PRODUCTION ? 'int-res-prod-mssql-managed.3650bfa943a7.database.windows.net' : 'sqlmi-aurum-dev.8df74733b712.database.windows.net'}
                readOnly
              />
            </FormLabel>
          )}
          <FormLabel>
            <LabelText>Auth type</LabelText>
            <HintText>Authentication type whether it's manual or auto</HintText>
            <Input ref={wsAuthTypeInputRef} defaultValue={envInLower === EnvironmentsName.PRODUCTION ? 'Automatic' : 'Manual' } readOnly />
          </FormLabel>
          {envInLower !== EnvironmentsName.PRODUCTION && (
            <>
              <FormLabel>
                <LabelText>Client id</LabelText>
                <HintText>Client id.</HintText>
                <Input ref={wsClientIdInputRef} />
              </FormLabel>
              <FormLabel>
                <LabelText>Client secret</LabelText>
                <HintText>Client secret.</HintText>
                <Input ref={wsClientSecretInputRef} />
              </FormLabel>
            </>
          )}
          <FormButtonContainer>
            <Button type="submit" className="govuk-button" data-module="govuk-button">Create workspace</Button>
            <CancelButton onClick={() => routeChange()}>Cancel</CancelButton>
          </FormButtonContainer>  
        </FormWrapper>
      </form>
    </>
  );
}
