import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { Button, Caption, Heading, ErrorText, LoadingBox } from 'govuk-react';

// import { OnlineStatus } from '../services/OnlineStatus';
import { RadioButton } from '../components/ui/RadioButton';
import { Fieldset } from '../components/ui/Fieldset';

import './CreateNewAirlockRequest.css';
import { WorkspaceContext } from '../contexts/WorkspaceContext';
import { loginRequest, trecoreServicesConfig } from '../components/Core/authConfig';
import { CallApiWithToken, HttpMethod } from '../components/Core/fetch';
import { ApiEndpoint } from '../components/models/apiEndPoints';
import { MessageCard } from '../components/Error/MessageCard';
import { AppRolesContext } from '../contexts/AppRolesContext';
import { IsOnlineContext } from '../contexts/IsOnlineContext';

export const CreateNewAirlockRequest = (props: any) => {
  const [requestType, setRequestType] = useState<null | string>(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [todaysExports, setTodaysExports] = useState<null | []>(null);
  const { instance, accounts } = useMsal();
  const workspaceCtx = useContext(WorkspaceContext);
  const appRolesCtx = useContext(AppRolesContext);
  const isOnlineCtx = useContext(IsOnlineContext);
  const [errorData, setErrorData] = useState(false);
  const wsId = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    workspaceCtx.workspace.properties && (
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
      }).then(async (response) => {
        await CallApiWithToken(
          response.accessToken,
          `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${wsId.id}/${ApiEndpoint.AirlockRequests}`,
          HttpMethod.Get,
          ''
        ).then(response => {
          const exports = response.airlockRequests.filter((item: any) => item.airlockRequest.type === "export" && item.airlockRequest.status !== "cancelled" && !item.allowedUserActions.includes("submit") && item.airlockRequest.createdBy.id === appRolesCtx.OId);
          console.log("exports: ", exports);
          const today = new Date().toDateString();
          setTodaysExports(exports.filter((item: any) => new Date(item.airlockRequest.updatedWhen * 1000).toDateString() === today));
          setLoading(false);
        }).catch((err: any) => {
          setErrorData(err);
          setLoading(false);
        })
      })
    )
  }, [workspaceCtx]);

  const onSubmit = (e: any) => {
    e.preventDefault();
    setError(false);
    requestType === null ? setError(true) : navigate(`/Workspaces/${wsId.id}/airlocks/${requestType}`);
  }

  return (
    <>
      <LoadingBox loading={loading}>
        {errorData ? (
          <MessageCard msgData={errorData} />
        ) : (
          <form onSubmit={onSubmit}>
            <Heading className="CreateNewAirlockRequest__heading">What would you like to request?</Heading>
            <Caption>Select a template</Caption>
            <>
              <Fieldset error={error}>
                {error && <ErrorText>Select a request type</ErrorText>}
                <RadioButton
                  label="Import file"
                  checked={requestType === "create-import"}
                  disabled={!isOnlineCtx.isOnline}
                  disabledText="Import function is only available outside your CPRD Safe workspace"
                  onChange={() => setRequestType("create-import")}
                />
                {todaysExports && (
                  todaysExports.length < 10 ? (
                    <RadioButton
                      label="Export file"
                      checked={requestType === "create-export"}
                      onChange={() => setRequestType("create-export")}
                      disabled={isOnlineCtx.isOnline}
                      disabledText="Export function is only available in your CPRD Safe workspace"
                    />
                  ) : (
                    <RadioButton
                      label="Export file"
                      checked={requestType === "create-export"}
                      onChange={() => setRequestType("create-export")}
                      disabled={true}
                      disabledText="You can submit a maximum of 10 export requests per day"
                    />
                  )
                )}
              </Fieldset>
              <Button type="submit" className="govuk-button CreateNewAirlockRequest__next">Next</Button>
            </>
          </form>
        )}
      </LoadingBox>
    </>
  )
}
