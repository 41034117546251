import { useEffect, useState } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import { LoadingBox } from "govuk-react";
import yn from "yn";

import { PageLayout } from "./components/layout/PageLayout";
import "./styles/App.css";
import { Workspaces } from "./pages/Workspaces";
import { Profile } from "./pages/Profile";
import { Home } from "./pages/Home";
import { WorkspaceServices } from "./pages/WorkspaceServices";
import { AccessiblityStatement } from "./pages/Accessiblity-Statement";
import { Contact } from "./pages/Contact";
import { PrivacyNotice } from "./pages/Privacy-notice";
import { TermsAndConditions } from "./pages/Terms-And-Conditions";
import NotFound from "./components/Error/NotFound";
import { DashSample } from "./pages/DashSample";
import { PrivateRoute } from "./components/layout/PrivateRoute";
import { CreateWorkspace } from "./components/workspaces/CreateWorkspace";
import { UpdateWorkspace } from "./components/workspaces/UpdateWorkspace";
import { Costs } from "./pages/Costs";
import { AirlockImportCreate } from "./components/Airlock/AirlockImportCreate";
import { AirlockImportFileUpload } from "./components/Airlock/AirlockImportFileUpload";
import { SharedServices } from "./pages/SharedServices";
import { CreateVM } from "./components/workspaces/CreateVM";
import { WorkspaceContext } from "./contexts/WorkspaceContext";
import { IsOnlineContext } from "./contexts/IsOnlineContext";
import { OnlineStatus } from "./services/OnlineStatus";
import { Workspace } from "./components/models/workspace";
import { ApiEndpoint } from "./components/models/apiEndPoints";
import { HttpMethod, ResultType } from "./components/Core/fetch";
import { useAuthApiCall } from "./components/hooks/useAuthAPICall";
import { AppRolesContext } from "./contexts/AppRolesContext";
import { WorkspaceProvider } from "./components/workspaces/WorkspaceProvider";
import { AirlockSubmit } from "./components/Airlock/AirlockSubmit";
import { AirlockReviewRequest } from "./components/Airlock/AirlockReviewRequest";
import { WorkspaceUserResources } from "./components/workspaces/WorkspaceUserResources";
import { HomeLink } from "./pages/HomeLink";
import { AirlockAdminHistory } from "./components/Airlock/Views/AirlockAdminHistory";
import { SecuredByRole } from "./components/hooks/SecuredByRole";
import { RoleName, WorkspaceRoleName } from "./components/models/roleNames";
import { CreateGuacamole } from "./components/workspaces/CreateGuacamole";
import { CreateGitea } from "./components/workspaces/CreateGitea";
import { CreateAtlas } from "./components/workspaces/CreateAtlas";
import { CreateNewWorkspaceService } from "./pages/CreateNewWorkspaceService";
import { ManageWorkspaces } from "./pages/ManageWorkspaces";
import { ManageWorkspace } from "./pages/ManageWorkspace";
import { ManageWorkspaceServices } from "./pages/ManageWorkspaceServices";
import { UpdateWorkspaceService } from "./pages/UpdateWorkspaceService";
import { CreateNewAirlockRequest } from "./pages/CreateNewAirlockRequest";
import { ManageUserResources } from "./pages/ManageUserResources";
import { UpdateUserResource } from "./pages/UpdateUserResource";
import { Support } from "./pages/Support";
import { AirlockExportTriageStageOne } from "./components/Airlock/ExportTriage/StageOne";
import { AirlockExportTriageStageTwo } from "./components/Airlock/ExportTriage/StageTwo";
import { AirlockExportTriageExemption } from "./components/Airlock/ExportTriage/Exemption";
import { AirlockExportTriageRejection } from "./components/Airlock/ExportTriage/Rejection";
import { AirlockCreate } from "./components/Airlock/AirlockCreate";
import { AirlockSuccess } from "./components/Airlock/AirlockSuccess";
import { AirlockReviewSubmitted } from "./components/Airlock/AirlockReviewSubmitted";
import { AirlockAuditScreen } from "./components/Airlock/AirlockAuditScreen";
import { AirlockDashboard } from "./components/Airlock/AirlockDashboard";

export default function App() {    
  const [appRoles, setAppRoles] = useState([] as Array<string>);
  const [isOnline, setIsOnline] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [selectedWorkspace, setSelectedWorkspace] = useState({} as Workspace);
  const [workspaceRoles, setWorkspaceRoles] = useState([] as Array<string>);  
  const [appOid, setAppOid] = useState(String);
  const apiCall = useAuthApiCall();

  let wsId = useParams();
  // set the app roles
  useEffect(() => {
    yn(process.env.REACT_APP_DEBUG) && console.info('APP: calling setAppRolesOnLoad');
    const setAppRolesOnLoad = async () => {      
      await apiCall(ApiEndpoint.Workspaces, HttpMethod.Get, undefined, undefined, ResultType.JSON, (roles: Array<string>) => {
        setAppRoles(roles);
      }, true);
    };
    setAppRolesOnLoad();
    OnlineStatus(setIsOnline, setLoading);
  }, [apiCall]);

  return (
    <AppRolesContext.Provider value={{
      roles: appRoles,
      setAppRoles: (roles: Array<string>) => { setAppRoles(roles);yn(process.env.REACT_APP_DEBUG) && console.info('App: AppRoles are..',roles); },
      OId: appOid,
      setOId: (oid:any)=> { setAppOid(oid);yn(process.env.REACT_APP_DEBUG) &&  console.info('App: AppRoles OId..',oid);},                        
    }}>
      <IsOnlineContext.Provider value={{
        isOnline: isOnline
      }}>
        <LoadingBox loading={loading}>
          <PageLayout>
            <Routes>
              <Route path="*" element={
                <Routes>
                  <Route path="/" element={<Home />}></Route>
                  <Route path="/Home" element={<Home />}></Route>
                  <Route path="/HomeLink" element={<HomeLink />}></Route>
                  <Route path="/Workspaces" element={<Workspaces />}></Route>
                  <Route path="/shared-services" element={<SharedServices />}></Route>
                  <Route path="/manage-workspaces" element={<ManageWorkspaces />} />
                  <Route path="/support" element={<Support />} />
                  <Route
                    path="*"
                    element={
                      <WorkspaceContext.Provider value={{
                        roles: workspaceRoles,
                        setRoles: (roles: Array<string>) => {yn(process.env.REACT_APP_DEBUG) &&  console.info("App: Workspace roles", roles); setWorkspaceRoles(roles) },
                        workspace: selectedWorkspace,
                        setWorkspace: (w: Workspace) => {yn(process.env.REACT_APP_DEBUG) &&  console.info("App: Workspace set", w); setSelectedWorkspace(w) },
                        workspaceApplicationIdURI: selectedWorkspace.properties?.scope_id
                      }}>
                        <Routes>
                        <Route path="/Profile" element={<Profile />}></Route>
                          <Route path="/Workspaces/:id" element={<WorkspaceServices />}></Route>
                          <Route path="/Workspaces/:id/update-workspace" element={<UpdateWorkspace />}></Route>
                          <Route path="/manage-workspaces/:id" element={<ManageWorkspace />}></Route>
                          <Route path="/manage-workspaces/:id/manage-workspace-services" element={<ManageWorkspaceServices />}></Route>
                          <Route path="/manage-workspaces/:id/manage-workspace-services/:rid/update-workspace-service" element={<UpdateWorkspaceService />}></Route>
                          <Route path="/manage-workspaces/:id/manage-workspace-services/:rid/manage-user-resources" element={<ManageUserResources />}></Route>
                          <Route path="/manage-workspaces/:id/manage-workspace-services/:rid/update-user-resource/:urid" element={<UpdateUserResource />}></Route>
                          <Route path="/manage-workspaces/:id/manage-workspace-services/:rid/createvm" element={<CreateVM />}></Route>
                          <Route path="/manage-workspaces/:id/manage-workspace-services/CreateNewWorkspaceService" element={<CreateNewWorkspaceService />} />
                          <Route path="/Workspaces/:id/workspace-services/:rid/createvm" element={<CreateVM />}></Route>
                          <Route path="/Workspaces/:id/workspace-services/:rid/user-resources" element={<WorkspaceUserResources />}></Route>
                          <Route path="/Workspaces/:id/airlocks" element={<AirlockDashboard />}></Route>
                          <Route path="/Workspaces/:id/Airlocks/create-new-airlock-request" element={<CreateNewAirlockRequest />}></Route>
                          <Route path="/Workspaces/:id/AirlockAdminHistory" element={<SecuredByRole element={<AirlockAdminHistory />} allowedRoles={[WorkspaceRoleName.AirlockManager]} workspaceAuth={true} errorString={"You must be an AirlockManager to access this area"}/>}></Route>
                          <Route path="/workspaces/:id/airlocks/create-import" element={<AirlockCreate type="import" />} />
                          <Route path="/workspaces/:id/airlocks/create-export" element={<AirlockCreate type="export" />} />
                          <Route path="/workspaces/:id/airlocks/airlock-export-triage-stage-1/:aid" element={<AirlockExportTriageStageOne />} />
                          <Route path="/workspaces/:id/airlocks/airlock-export-triage-stage-2/:aid" element={<AirlockExportTriageStageTwo />} />
                          <Route path="/workspaces/:id/airlocks/airlock-export-triage-exemption/:aid" element={<AirlockExportTriageExemption />} />
                          <Route path="/workspaces/:id/airlocks/airlock-export-triage-rejection/:aid" element={<AirlockExportTriageRejection />} />
                          <Route path="/workspaces/:id/airlocks/airlock-export-submit/:aid" element={<AirlockSubmit />} />
                          <Route path="/workspaces/:id/airlocks/airlock-import-submit/:aid" element={<AirlockSubmit />} />
                          <Route path="/workspaces/:id/airlocks/airlock-import-success/:aid" element={<AirlockSuccess />} />
                          <Route path="/workspaces/:id/airlocks/airlock-export-success/:aid" element={<AirlockSuccess />} />
                          <Route path="/Workspaces/:id/Airlocks/AirlockReviewRequest/:aid" element={<AirlockReviewRequest />}></Route>
                          <Route path="/workspaces/:id/airlocks/airlock-review-request/:aid/submitted" element={<AirlockReviewSubmitted />} />
                          <Route path="/workspaces/:id/airlocks/airlock-request-audit/:aid" element={<AirlockAuditScreen />} />
                          <Route path="/Workspaces/:id/CreateNewWorkspaceService" element={<CreateNewWorkspaceService />} />
                          <Route path="/Workspaces/:id/createguacamole" element={<SecuredByRole element={<CreateGuacamole />} allowedRoles={[WorkspaceRoleName.WorkspaceOwner]} workspaceAuth={true} errorString={"You must be an Admin to access this area (create Guacamole)"}/>}></Route>                                
                          <Route path="/Workspaces/:id/creategitea" element={<SecuredByRole element={<CreateGitea />} allowedRoles={[WorkspaceRoleName.WorkspaceOwner]} workspaceAuth={true} errorString={"You must be an Admin to access this area (create Gitea)"}/>}></Route>
                          <Route path="/Workspaces/:id/createatlas" element={<SecuredByRole element={<CreateAtlas />} allowedRoles={[WorkspaceRoleName.WorkspaceOwner]} workspaceAuth={true} errorString={"You must be an Admin to access this area (create Atlas)"}/>}></Route> 
                        </Routes>
                        <WorkspaceProvider/>
                      </WorkspaceContext.Provider>                                                        
                    }
                  />
                  <Route path="/Costs/:id" element={<Costs />}></Route>
                  <Route path="/Accessiblity-Statement" element={<AccessiblityStatement />}></Route>
                  <Route path="/Contact" element={<Contact />}></Route>
                  <Route path="/Privacy-Notice" element={<PrivateRoute><PrivacyNotice /></PrivateRoute> }></Route>                     
                  <Route path="/terms-and-conditions" element={<TermsAndConditions />}></Route>                
                  <Route path="/DashSample" element={<DashSample />}></Route>          
                  <Route path="/CreateWorkspace" element={<SecuredByRole element={<CreateWorkspace />} allowedRoles={[RoleName.TREAdmin]} workspaceAuth={false} errorString={"You must be an Admin to access this area (create workspace)"}/>}></Route>
                  <Route path="*" element={<NotFound />} />
                </Routes>
              } />                
            </Routes>
          </PageLayout>
        </LoadingBox>
      </IsOnlineContext.Provider>
    </AppRolesContext.Provider>
  );
}
