import yn from "yn";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param {string} accessToken
 * @param {string} apiEndpoint
 */

export enum ResultType {
  JSON = "JSON",
  Text = "Text",
  None = "None"
}

export enum HttpMethod {
  Get = "GET",
  Post = "POST",
  Patch = "PATCH",
  Delete = "DELETE"
}

export async function CallApiWithToken(accessToken:string, apiEndpoint:string,method:HttpMethod,bodystr:string | { workspace_ids: string[] } | null, etag?: string) {

  {/*}  const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
*/}
    const opts: RequestInit = {
      mode: "cors",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'etag': etag ? etag : ""
      },
      method: method
    }
    
    if (bodystr) opts.body = JSON.stringify(bodystr);

    return fetch(apiEndpoint, opts).then(response => {
      if(!response.ok) {
          //if(process.env.NODE_ENV=='development')
          
        switch(response.status) {
          case 403:
            throw new Error('http status: (' + response.status + '), status text: ' + response.statusText + ' (User has no access)' );
          case 401:    
            throw new Error('http status: (' + response.status + '), status text: ' + response.statusText + ' (resource not available)' );
          default:
            throw new Error('http status: (' + response.status + '), status text: ' + response.statusText);
        }
      }              
      yn(process.env.REACT_APP_DEBUG) && console.log('response is Ok...',response);
      return response.json();
      })
        // to log body response
        // .then((data: any) => {
        //   console.log("data: ", data);
        // })
    .catch((error) => {
      switch(error.message) {
        case 'Failed to fetch':
          throw new Error('An error has occured calling CPRD Safe Core API: ' + error.message + ' (SSL issue)');
        default:
          throw new Error(error);
      }
    });
}
