export enum ApiEndpoint {
    Workspaces = 'workspaces',
    WorkspaceServices = 'workspace-services',
    WorskspaceCosts = 'workspace_costs',
    UserResources = 'user-resources',
    SharedServices = 'shared-services',
    ActiveVMs = 'active-vm-count',
    AirlockRequests = 'requests',
    AirlockLink = 'link',
    AirlockSubmit = 'submit',
    AirlockCancel = 'cancel',
    AirlockReview = 'review',
    AirlockTriage = 'triage',
    AirlockExitTriage = 'exit-triage',
    AirlockStatistics = 'statistics',
    AirlockExitStatistics = 'exit-statistics',
    AirlockContact = 'contact',
    WorkspaceTemplates = 'workspace-templates',
    WorkspaceServiceTemplates = 'workspace-service-templates',
    UserResourceTemplates = 'user-resource-templates',
    SharedServiceTemplates = 'shared-service-templates',
    Support = 'support',
    Operations = 'operations',
    InvokeAction = 'invoke-action'
}
