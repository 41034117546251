import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import './MessageCard.css';
import { useEffect } from "react";

export const MessageCard = (props: any) => { 
  return (
    <>
      <div className="govuk-error-summary" aria-labelledby="error-summary-title" role="alert" data-module="govuk-error-summary">
        <h2 className="govuk-error-summary__title" id="error-summary-title">There is a problem</h2>
        <div className="govuk-error-summary__body">
          <ul className="govuk-list govuk-error-summary__list">
            <li>
              <div>No Workspaces found or service unavailable. Please contact system administrator</div>                
            </li>                
            <li>Details: </li>
            <li>{props.msgData.message}</li>
          </ul>
        </div>
      </div>
      <Button as={Link} to='/HomeLink' className="btn btn-light" >Return to Home page</Button>                
    </>
  );
}

export const CustomMessageCard = (props: any) => {
  return (
    <>
      <div className="govuk-error-summary" aria-labelledby="error-summary-title" role="alert" data-module="govuk-error-summary">
        <h2 className="govuk-error-summary__title" id="error-summary-title">There is a problem</h2>
        <div className="govuk-error-summary__body">
          <ul className="govuk-list govuk-error-summary__list">
            <li>{props.msgData}</li>
          </ul>
        </div>
      </div>
      <Button as={Link} to='/HomeLink' className="btn btn-light" >Return to Home page</Button>
    </>
  );
}


export const CustomMessageSuccessNextCard = (props: any) => {
  let navigate = useNavigate(); 
  let btnNext = 'Next';
  const stateObject = props.props.element;

  const NextAction = (path: string) => {
    navigate(path);
  }


  console.log("stateObject: ", stateObject);

  return (
    <> 
      <div className="govuk-notification-banner CustomMessageSuccessNextCard" role="region" aria-labelledby="govuk-notification-banner-title" data-module="govuk-notification-banner">
        <div className="govuk-notification-banner__header">
          <h2 className="govuk-notification-banner__title" id="govuk-notification-banner-title">Information</h2>
        </div>
        <div className="govuk-notification-banner__content">
          <p className="govuk-notification-banner__heading">{props.props.msgData}</p>
        </div>
      </div>
      <div className="CustomMessageSuccessNextCard__button-wrapper">
        <Button as={Link} to={'/' + props.props.linkData} className="btn btn-light" >Return to Airlock dashboard</Button>
        {props.props.selectedFile ? (
          <Link state={{ stateObj: stateObject, selectedFile: props.props.selectedFile }} type="button" className="govuk-button CustomMessageSuccessNextCard__button" data-module="govuk-button" onClick={() => NextAction(props.props.nextUrl)} to={props.props.nextUrl}>{btnNext}</Link>
        ) : (
          <Link state={{ stateObj: stateObject }} type="button" className="govuk-button CustomMessageSuccessNextCard__button" data-module="govuk-button" onClick={() => NextAction(props.props.nextUrl)} to={props.props.nextUrl}>{btnNext}</Link>
        )}
      </div>
    </>
  );
}


export const CustomMessageSuccessCard = (props:any) => {
  return (
    <>
      <div className="govuk-notification-banner" role="region" aria-labelledby="govuk-notification-banner-title" data-module="govuk-notification-banner">
        <div className="govuk-notification-banner__header">
          <h2 className="govuk-notification-banner__title" id="govuk-notification-banner-title">Information</h2>
        </div>
        <div className="govuk-notification-banner__content">
          <p className="govuk-notification-banner__heading">{props.props.msgData}</p>
        </div>
      </div>
      <Button as={Link} to={'/' + props.props.linkData} className="btn btn-light" >{props.props.linkMsg ? props.props.linkMsg : "Return to Home page"}</Button>
    </>
  );
}


export const CustomMessageErrorCard = (props:any)=> {   
  return (
    <>
      <div className="govuk-error-summary" aria-labelledby="error-summary-title" role="alert" data-module="govuk-error-summary">
        <h2 className="govuk-error-summary__title" id="error-summary-title">There is a problem</h2>
        <div className="govuk-error-summary__body">
          <ul className="govuk-list govuk-error-summary__list">
            <li>{props.props.msgData}</li>
          </ul>
        </div>
      </div>
      <Button as={Link} to={'/' + props.props.linkData} className="btn btn-light" >Return to Home page</Button>
    </>
  );
}


