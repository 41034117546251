import React from "react";
import { Button, Heading, Panel } from "govuk-react";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import "./AirlockSuccess.css";

export const AirlockSuccess = () => {
  const wsId = useParams();
  const location: any = useLocation();
  const navigate = useNavigate();

  const backToDashboard = () => {
    navigate(`/workspaces/${wsId.id}/airlocks/`);
  }

  // Will this page be deleted through export request changes??

  return (
    <>
      <div className="airlock-success__wrapper">
        <Panel title="Request complete">
          You reference number
          <br />
          <strong>{wsId.aid}</strong>
        </Panel>
      </div>
      {location.state && (
        location.state.type !== "import" && <p>We have sent you a confirmation email</p>
      )}
      <Heading as="h2">What happens next?</Heading>
      {location.state && (
        <p>{location.state.message}</p>
      )}
      <Button className="govuk-button" onClick={() => backToDashboard()}>Back to airlock dashboard</Button>
    </>
  )
}
