import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { LoadingBox, Heading, Details, Button } from "govuk-react";
import { useMsal } from "@azure/msal-react";

import { Lede } from "../ui/Lede";
import { Title } from "../ui/Title";
import { WorkspaceContext } from "../../contexts/WorkspaceContext";
import { NotificationBox } from "../ui/NotificationBox";
import { FormWrapper } from "../ui/FormWrapper";
import { AirlockDetailInfocmp } from "./AirlockDetailInfocmp";
import { TableHead } from "../ui/TableHead";
import { FormButtonContainer } from "../ui/FormButtonContainer";
import { loginRequest, trecoreServicesConfig } from "../Core/authConfig";
import { CallApiWithToken, HttpMethod } from "../Core/fetch";
import { ApiEndpoint } from "../models/apiEndPoints";
import { AppRolesContext } from "../../contexts/AppRolesContext";
import { MessageCard } from "../Error/MessageCard";
import { useAuthApiCall } from "../hooks/useAuthAPICall";
import uploadFileToBlob, { checkBlobsInContainer } from "../Core/azureStorageBlob";
import { IsOnlineContext } from "../../contexts/IsOnlineContext";

import { AirlockRequestType } from "../models/airlock";

export const AirlockSubmit = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [todaysExports, setTodaysExports] = useState([]);
  const [errorData, setErrorData] = useState(null);
  const [fileName, setFileName] = useState<any>(null);
  const [arId, setArId] = useState<null | string>(null);
  const [sasToken, setSasToken] = useState<null | string>(null);
  const wsId = useParams();
  const location = useLocation();
  const stateData: any = location.state;
  const navigate = useNavigate();
  const workspaceCtx = useContext(WorkspaceContext);
  const appRolesCtx = useContext(AppRolesContext);
  const isOnlineCtx = useContext(IsOnlineContext);
  const apiCall = useAuthApiCall();
  const requestId = wsId.aid;
  const { instance, accounts } = useMsal();

  useEffect(() => {
    workspaceCtx.workspace.properties && stateData.stateObj.type === "export" && (
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
      }).then(async (response) => {
        await CallApiWithToken(
          response.accessToken,
          `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${workspaceCtx.workspace.id}/${ApiEndpoint.AirlockRequests}`,
          HttpMethod.Get,
          ''
        ).then(response => {
          const exports = response.airlockRequests.filter((item: any) => item.airlockRequest.type === "export" && item.airlockRequest.status !== "cancelled" && !item.allowedUserActions.includes("submit") && item.airlockRequest.createdBy.id === appRolesCtx.OId);
          const today = new Date().toDateString();
          setTodaysExports(exports.filter((item: any) => new Date(item.airlockRequest.updatedWhen * 1000).toDateString() === today));
        }).catch((err: any) => {
          console.log("err: ", err);
        })
      })
    )
  }, [workspaceCtx, stateData])

  const checkForBlob = async () => {
    console.log("checkForBlob running");
    setLoading(true);
    let airlockSaSTokenUrl = `${ApiEndpoint.Workspaces}/${wsId.id}/${ApiEndpoint.AirlockRequests}/${requestId}/${ApiEndpoint.AirlockLink}`;
    const linkObject = (await apiCall(airlockSaSTokenUrl, HttpMethod.Get, workspaceCtx.workspaceApplicationIdURI));



    // let airlockSaSTokenurl=`${ApiEndpoint.Workspaces}/${wsId.id}/${ApiEndpoint.AirlockRequests}/${wsId.aid}/${ApiEndpoint.AirlockLink}`;

    //     const linkObject = (await apiCall(airlockSaSTokenurl, HttpMethod.Get, workspaceCtx.workspaceApplicationIdURI));
    
    await checkBlobsInContainer(linkObject.containerUrl.replace(requestId, ''), requestId!)
      .then((response: any) => {
        console.log("checkWholeBlobsInContainer checked for blobl: ", response);
        setFileName(response);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log("err: ", err);
        setLoading(false);
      });
  }

  useEffect(() => {
    stateData.stateObj.type === "export" ? checkForBlob() : setLoading(false);
  }, []);

  const tableHeaders = [
    {
      header: "File name"
    },
    {
      header: "File size"
    }
  ];

  const backToImport = () => {
    navigate(`/workspaces/${wsId.id}/airlocks/create-import`, { state: { stateObj: stateData.stateObj, selectedFile: stateData.selectedFile } });
  }

  const onImport = (e: React.SyntheticEvent) => {
    console.log("onImport being run");
    e.preventDefault();
    setLoading(true);

    // create payload with name and business justification
    const payload: any = {
      type: AirlockRequestType.Import,
      title: stateData.stateObj.title,
      businessJustification: stateData.stateObj.businessJustification,
      isEUUAAccepted: stateData.stateObj.isEUUAAccepted
    }

    console.log("arId: ", arId);
    // we have to split the request based on us having a arId or not, incase it fails whilst preparing the storage container

    !arId ? (
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
      }).then(async (initialResponse) => {
        await CallApiWithToken(
          initialResponse.accessToken,
          `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${wsId.id}/${ApiEndpoint.AirlockRequests}`,
          HttpMethod.Post,
          payload
        ).then(async (response) => {
          // retrieve airlock request id from response, setArId
          console.log("airlock request created");
          setArId(response.airlockRequest.id);
          // get sasToken for upload
          await CallApiWithToken(
            initialResponse.accessToken,
            `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${wsId.id}/${ApiEndpoint.AirlockRequests}/${response.airlockRequest.id}/${ApiEndpoint.AirlockLink}`,
            HttpMethod.Get,
            null
          ).then(async (sasTokenResponse) => {
            // upload file
            console.log("sasToken created");
            setSasToken(sasTokenResponse.containerUrl);
            // sasTokenResponse.containerUrl
            // I think we need to restart from here
            await uploadFileToBlob(
              stateData.selectedFile,
              sasTokenResponse.containerUrl.replace(response.airlockRequest.id,''),
              response.airlockRequest.id
            ).then(async (fileRresponse) => {
              // check file has been uploaded
              console.log("check file has been upload");
              await checkBlobsInContainer(
                sasTokenResponse.containerUrl.replace(response.airlockRequest.id,''),
                response.airlockRequest.id
              ).then(async (checkBlobResponse) => {
                // successful
                console.log("successful");
                await CallApiWithToken(
                  initialResponse.accessToken,
                  `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${wsId.id}/${ApiEndpoint.AirlockRequests}/${response.airlockRequest.id}/${ApiEndpoint.AirlockSubmit}`,
                  HttpMethod.Post,
                  null
                ).then(response => {
                  navigate(`/workspaces/${wsId.id}/airlocks#imports`, { state: { requestComplete: true, aID: response.airlockRequest.id } });
                }).catch((err: any) => {
                  setErrorData(err);
                  setLoading(false);
                })
              }).catch((err: any) => {
                console.log("catch 1");
                setErrorData(err);
                setLoading(false);
              })
            }).catch((err: any) => {
              console.log("catch 2");
              setErrorData(err);
              setLoading(false);
            })
          }).catch((err: any) => {
            console.log("catch 3");
            setErrorData(err);
            setLoading(false);
          })
        }).catch((err: any) => {
          console.log("catch 4");
          setErrorData(err);
          console.log("err: ", err);
          setLoading(false);
        })
      })
    ) : (
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
      }).then(async (initialResponse) => {
        // here if they have a airlock request ID
        await uploadFileToBlob(
          stateData.selectedFile,
          sasToken!.replace(arId,''),
          arId
        ).then(async (fileRresponse) => {
          // check file has been uploaded
          console.log("check file has been upload");
          await checkBlobsInContainer(
            sasToken!.replace(arId,''),
            arId
          ).then(async (checkBlobResponse) => {
            // successful
            console.log("successful");
            // we need to SUBMIT the request here
            await CallApiWithToken(
              initialResponse.accessToken,
              `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${wsId.id}/${ApiEndpoint.AirlockRequests}/${arId}/${ApiEndpoint.AirlockSubmit}`,
              HttpMethod.Post,
              null
            ).then(response => {
              navigate(`/workspaces/${wsId.id}/airlocks#imports`, { state: { requestComplete: true, aID: arId } });
            }).catch((err: any) => {
              setErrorData(err);
              setLoading(false);
            })
          }).catch((err: any) => {
            console.log("catch 1");
            setErrorData(err);
            setLoading(false);
          })
        }).catch((err: any) => {
          console.log("catch 2");
          setErrorData(err);
          setLoading(false);
        })
      })
    )
  }

  const onSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (workspaceCtx.workspace.properties !== undefined && workspaceCtx.workspace.id === wsId.id) {
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
      }).then(async (response) => {
        await CallApiWithToken(
          response.accessToken,
          `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${wsId.id}/${ApiEndpoint.AirlockRequests}/${wsId.aid}/${ApiEndpoint.AirlockSubmit}`,
          HttpMethod.Post,
          null
        ).then(response => {
          let message;

          if (stateData.stateObj.triageLevel === "L4a: Rejected" || stateData.stateObj.triageLevel === "L4: Rejected") {
            message = "Unfortunately, your output request has been automatically rejected as it doesn't meet CPRD disclosure control rules. Please refer to xxxxx for information on how assessments are made."
          } else if (stateData.stateObj.triageLevel === "L2a: No ACRO") {
            message = "Our automated assessment calculated your outputs meet CPRD disclosure rules. Your outputs will now be passed to two of our researchers for review. This normally takes a minimum of 15 working days, and we will let you know when the assessment is complete and whether you can download your outputs. Please note the use of ACRO tools will speed up the assessment process.";
          } else if (stateData.stateObj.triageLevel === "L2b: ACRO") {
            message = "Our automated assessment calculated your outputs meet CPRD disclosure rules. Your outputs will now be passed to one of our researchers for review. We aim to complete this review within 5 working days, and we will let you know when the assessment is complete and whether you can download your outputs. Thank you for using ACRO tools - this will speed up the assessment process.";
          } else if (stateData.stateObj.triageLevel === "L3: Exemption") {
            message = "Due to the complexity of your outputs, we have escalated the review to specialists within our team. We aim to complete this review within 15 working days, and we will let you know when the assessment is complete and whether you can download your outputs.";
          } else {
            message = "Our automated assessment calculated your outputs meet CPRD disclosure rules. They will now be passed to an Output Checker for release within 3 working days.";
          };

          if (stateData.stateObj.type === "import") {
            navigate(`/workspaces/${wsId.id}/airlocks#imports`, { state: { requestComplete: true, aID: wsId.aid } });
          } else {
            navigate(`/workspaces/${wsId.id}/airlocks/airlock-export-success/${wsId.aid}`, { state: { message: message, type: stateData.stateObj.type } })
          };
        }).catch((err: any) => {
          setErrorData(err);
          setLoading(false);
        })
      })
    }
  }

  return (
    <LoadingBox loading={loading}>
      {errorData && (
        <MessageCard msgData={errorData} />
      )}
      {stateData && ((isOnlineCtx.isOnline && stateData.stateObj.type === "export") || (!isOnlineCtx.isOnline && stateData.stateObj.type === "import") ? (
        <>
          {stateData.stateObj.type === "export" ? (
            <Lede>Export function is only available in your CPRD Safe workspace</Lede>
          ) : (
            <Lede>Import function is only available outside your CPRD Safe workspace</Lede>
          )}
        </>
      ) : (
        <>
          {location.state && stateData.updatedMessage && (
            <NotificationBox
              error={null}
              text=""
              updated={stateData.updatedMessage}
            />
          )}
          <form onSubmit={stateData.stateObj.type === "import" ? onImport : onSubmit}>
            <FormWrapper>
              <Title>Check your details before sending your request</Title>
              <AirlockDetailInfocmp stateData={stateData.stateObj} workspaceName={workspaceCtx.workspace.properties && workspaceCtx.workspace.properties.display_name} />
              <Heading as="h2" size="M">Reason for request</Heading>
              <Details summary="Show">
                {stateData.stateObj.businessJustification}
              </Details>
              {(stateData.selectedFile || fileName) && (
                <>
                  <Heading as="h3" size="M">File details</Heading>
                  <Details summary="Show">
                    <table>
                      <TableHead headers={tableHeaders} />
                      <tbody>
                        <tr>
                          <td>{stateData.selectedFile ? stateData.selectedFile.name : fileName.name}</td>
                          <td>{stateData.selectedFile && (stateData.selectedFile.size/(1024**2)).toFixed(2)} MB</td>
                        </tr>
                      </tbody>
                    </table>
                  </Details>
                </>
              )}
              <p>By submitting this request I confirm the details I have provided are correct.</p>
              {stateData.stateObj.type === "export" ? (
                todaysExports.length < 10 ? (
                  <FormButtonContainer>
                    <Button className="govuk-button" type="submit">Submit</Button>
                  </FormButtonContainer>
                ) : (
                  <Lede>You can only submit 10 export requests per day</Lede>
                )
              ) : (
                <FormButtonContainer>
                  <Button className="govuk-button" onClick={() => backToImport()}>Back</Button>
                  <Button className="govuk-button" type="submit">Submit</Button>
                </FormButtonContainer>
              )}
            </FormWrapper>
          </form>
        </>
      ))}
    </LoadingBox>
  )
}
