import React from "react";
import { capitalizeFirst } from "../hooks/useStringFunc";

import { GetStatusTag } from "../ui/GetTag";

type AirlockDetailInfocmpProps = {
  stateData: any;
  workspaceName: string;
}

export const AirlockDetailInfocmp = ({stateData, workspaceName}: AirlockDetailInfocmpProps) => {
  const airlockObj = stateData;

  const createdDate = airlockObj && airlockObj.type === "export" ? new Date(airlockObj.createdWhen * 1000).toLocaleDateString() : airlockObj.createdWhen;
  return (
    <>
      {airlockObj && (
        <article className="AirlockFileUploadcmp__wrapper">
          <div className="govuk-summary-list__row" >
            <dt className="govuk-summary-list__key">Requestor:</dt>
            <dd className="govuk-summary-list__value">{airlockObj.createdBy.name}</dd>
          </div>
          <div className="govuk-summary-list__row" >
            <dt className="govuk-summary-list__key">Type:</dt>
            <dd className="govuk-summary-list__value">{capitalizeFirst(airlockObj.type)}</dd>
          </div>
          <div className="govuk-summary-list__row" >
            <dt className="govuk-summary-list__key">Status:</dt>
            <dd className="govuk-summary-list__value">
              <GetStatusTag status={airlockObj.status} />
            </dd>
          </div>
          <div className="govuk-summary-list__row" >
            <dt className="govuk-summary-list__key">Workspace:</dt>
            <dd className="govuk-summary-list__value">{workspaceName}</dd>
          </div>        
          <div className="govuk-summary-list__row" >
            <dt className="govuk-summary-list__key">Created date:</dt>
            <dd className="govuk-summary-list__value">{createdDate}</dd>
          </div>
        </article>
      )}
    </>
  );
}
